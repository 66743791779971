import React, { useEffect, useState } from "react";
import ButtonGeneral from "components/buttons/ButtonGeneral";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";

/**
 * This navbar is used in the panel page
 * @param {props} path the parent path of the routes
 * @param {props} sections used to create the buttons of the navbar and the routes, have to be an array of objects with the next structure:
 * {
 *  label: "string",
 *  route: "string"
 * }
 * @returns a navbar with the buttons of the sections
 */
const NavbarPanel = ({ path, sections }) => {
  // Notice, We are using the useState instead of props, This depends on the parent component, not the navbar
  const location = useLocation();
  const [currentShow, setCurrentShow] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    let lastEndpoint = pathSegments[pathSegments.length - 1];
    setCurrentShow(lastEndpoint);
  }, [location]);

  const handleClick = (selected) => {
    setCurrentShow(selected);
  };

  return (
    <>
      {sections.map((section) => (
        <div className="col-lg-2 col-md-3 col-4" key={section.route}>
          <Link className="link" to={`${path}/${section.route}`}>
            <ButtonGeneral
              className={`button-general ${
                currentShow === section.route ? "button-general-hover" : ""
              }`}
              expresion={() => handleClick(section.route)}
              text={section.label}
            />
          </Link>
        </div>
      ))}
    </>
  );
};

export { NavbarPanel };
