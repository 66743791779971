export const types = {
  read: "read",
  form: "form",
};

export const typeSections = [
  {
    value: types.form,
    label: "Form",
  },
  {
    value: types.read,
    label: "Reading",
  },
];

//export const urlBase = "http://localhost:5001";
export const urlBase = process.env.REACT_APP_API_BACK;
