/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { findfield } from "./Fields";
import { ElementOptions } from "./MenuOptions/ElementOptions";
import { HiOutlineTrash } from "react-icons/hi";
import "./styles.css";
import { BoardContext } from "providers/boardProvider";

const Element = ({ key, field, left, top }) => {
  const {
    updateElement,
    deleteAction,
    previewAction,
    deleteElement,
    updateSize,
    boardHeight,
  } = useContext(BoardContext);

  const [, drag] = useDrag({
    item: { ...field, left, top },
    type: "element",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [height, setHeight] = useState(field.height);

  const handleResize = (e) => {
    const newHeight = e.target.offsetHeight;

    const maxHeight = boardHeight;

    const clampedHeight = Math.min(newHeight, maxHeight);

    setHeight(clampedHeight);
    updateSize(field.id, clampedHeight);
  };

  // --------------------- Controla el menu flotante ---------------------
  const [menuVisible, setMenuVisible] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Evita que aparezca el menú contextual predeterminado
    // setMenuPosition({ x: e.clientX, y: e.clientY });
    setMenuVisible(true);
    document.addEventListener("click", handleClickOutside); // Agrega un listener para cerrar el menú al hacer clic fuera de él
  };

  const handleClickOutside = () => {
    setMenuVisible(false);
    document.removeEventListener("click", handleClickOutside); // Elimina el listener cuando se cierra el menú
  };

  const handleMenuClick = (e) => {
    e.stopPropagation(); // Evita que el clic se propague hasta el contenedor principal
  };
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };
  // ---------------------------------------------------------------------

  return (
    <>
      <div
        key={key}
        ref={drag}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: "absolute",
          left,
          top,
          width: "450px",
          height: `${deleteAction ? height + 45 : height + 10}px`,
          resize: "vertical",
          overflow: "auto",
          animation: deleteAction ? "shake 0.5s infinite" : "none",
          transformOrigin: "center center",
          border: !previewAction ? "0.5px solid grey" : "none",
          pointerEvents: previewAction ? "none" : "",
          cursor: isMouseOver ? "move" : "default",
        }}
        onClickCapture={handleResize}
        onAuxClickCapture={() => {}}
        onContextMenu={handleContextMenu}
      >
        {!previewAction && (
          <span
            className="corner-text"
            style={{
              position: "absolute",
              top: "1px",
              right: "0px",
              color: "black",
              fontSize: "8px",
            }}
          >
            {field.name}
          </span>
        )}
        {deleteAction && (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => deleteElement(field.id)}
          >
            <HiOutlineTrash />
          </button>
        )}
        {findfield(field)}
      </div>
      {menuVisible && (
        <div
          style={{
            position: "absolute",
            top: field.top,
            left: field.left,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 9999,
          }}
          onClick={handleMenuClick}
        >
          <ElementOptions
            field={field}
            updateElement={updateElement}
            handleClickOutside={handleClickOutside}
          />
        </div>
      )}
    </>
  );
};

export { Element };
