import React, { useState, useEffect, useRef } from "react";
import "../ComponetStylesFlex.css";
import Navbar from "./Navbar";
import ButtonIconLow from "../buttons/ButtonIconLow";
import ButtonArrastre from "../assets/BOTON DE ARRASTRE.svg";

import { Outlet } from "react-router-dom";

import "./MainPanel/styles.css";
import { SideBar } from "./MainPanel/SideBar";

const MainPanel = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleOutsideClick = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setDrawerOpen(false);
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [drawerOpen]);
  return (
    <div className="container-fluid">
      <div className="row d-md-none d-block">
        <div className="col-2">
          <ButtonIconLow icon={ButtonArrastre} expression={toggleDrawer} />
        </div>
        <div className="container mt-5">
          <div ref={drawerRef} className={`drawer ${drawerOpen ? "open" : ""}`}>
            <div className="drawer-content p-4">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5">
        <Navbar />
      </div>

      <div className="row d-flex p-0 ">
        <div className="col-lg-2 col-md-3 col-4 ps-2 d-none d-md-block">
          <SideBar />
        </div>

        <div className="col-lg-10 col-md-9 ps-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default MainPanel;
