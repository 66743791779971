import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToolIcon from "../../assets/tool-icon.svg";
import usersIcon from "../../assets/users.svg";
import CategoryIcon from "../../assets/menu-icon.svg";
import { useLocation } from "react-router-dom";
// import BannerIcon from "../../assets/speak-icon.svg";
import SettingIcon from "../../assets/settingIcon.svg";
import ProfileIcon from "../../assets/ProfileIcon.svg";

import ButtonSideBar from "../../buttons/ButtonSideBar";

const SideBar = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentSideBarPath = pathSegments[2];
    setCurrentPath(currentSideBarPath);
  }, [location]);

  const isShown = {
    profile: currentPath === "profile",
    categories: currentPath === "categories",
    general: currentPath === "general",
    users: currentPath === "users",
    preferences: currentPath === "preferences",
  };

  return (
    <>
      <div className="box-container">
        <h3 className="ps-2 " id="midle-title" style={{ paddingTop: "11px" }}>
          ¡Crea tu <br /> espacio!
        </h3>
        <div className="line mt-3" />
        <div id="text-light">
          <div className="mt-2">
            <Link className="link" to="/panel/profile">
              <ButtonSideBar
                icon={ProfileIcon}
                text={"Profile"}
                className={`button-sidebar ${
                  isShown.profile ? "button-sidebar-hover" : ""
                }`}
              />
            </Link>
          </div>
          <div className="mt-2">
            <Link className="link" to="/panel/general">
              <ButtonSideBar
                icon={ToolIcon}
                text={"General"}
                className={`button-sidebar ${
                  isShown.general ? "button-sidebar-hover" : ""
                }`}
              />
            </Link>
          </div>
          <div className="mt-2">
            <Link className="link" to="/panel/users">
              <ButtonSideBar
                icon={usersIcon}
                text={"Users"}
                className={`button-sidebar ${
                  isShown.users ? "button-sidebar-hover" : ""
                }`}
              />
            </Link>
          </div>
          <div className="mt-3">
            <Link className="link" to="/panel/categories">
              <ButtonSideBar
                icon={CategoryIcon}
                text={"Categories"}
                className={`button-sidebar ${
                  isShown.categories ? "button-sidebar-hover" : ""
                }`}
              />
            </Link>
          </div>
          {/* <div className="mt-2">
            <Link className="link" to="/panel/banners">
              <ButtonSideBar
                expression={handleClickBanner}
                icon={BannerIcon}
                text={"Banners"}
                className={`button-sidebar ${
                  isShownBanner ? "button-sidebar-hover" : ""
                }`}
              />
            </Link>
          </div> */}
        </div>
      </div>
      <div className="box-container mt-3">
        <div id="text-light">
          {/* <Link className="link" to="/panel/preferences"> */}
          <ButtonSideBar
            icon={SettingIcon}
            text={"Preferences"}
            className={`button-sidebar ${
              isShown.preferences ? "button-sidebar-hover" : ""
            }`}
          />
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export { SideBar };
