import React from "react";
import "../ComponetStylesFlex.css";
import { NavbarPanel } from "containers/NavbarPanel";
import { Outlet } from "react-router-dom";

function SectionPanel() {
  return (
    <div className="container-fluid">
      <div className="row"></div>
      <div className="row p-0">
        <NavbarPanel
          path="sections"
          sections={[{ label: "Sections", path: "sections" }]}
        />
      </div>
      <div className="row mt-3">
        <div className="col-9" style={{ width: "60%" }}>
          <div className="line" />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-10 col 12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SectionPanel;
