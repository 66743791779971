import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Login/Login";
import MainPanel from "./components/Views/MainPanel";
import Profile from "./components/Views/Profile";
import General from "./components/Views/General";
import Users from "./components/Views/Users";
import Categories from "./components/Views/Categories";
import User from "./components/Views/Profile/User";
import AddressForm from "./components/Views/Profile/AddressForm";
import LinksUser from "./components/Views/Profile/LinksUser";
import PaymentMethods from "./components/Views/Profile/Payments";
import Company from "components/Views/General/Company";
import Color from "components/Views/General/Color";
import LinksCompany from "components/Views/General/LinksCompany";
import ListUsers from "components/Views/Users/users";
import CategoriesInside from "components/Views/Categories/CategoriesInside";
import Details from "components/Views/Categories/Details";
import SectionPanel from "./components/Views/Section";
import Section from "components/Views/Sections/Section";
import BannerPanel from "./components/Views/Banners";
import Banner from "components/Views/Banner/Banner";
import { DesignBoard } from "components/Views/DesignBoard";
import { NotificationContainer } from "components/common/react-notifications";
import { SectionResponse } from "components/Views/Sections/SectionResponse/SectionResponse";

function App() {
  return (
    <div className="App">
      <NotificationContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/panel" default element={<MainPanel />}>
            <Route path="" element={<Navigate to="profile" replace />} />
            <Route path="profile" element={<Profile />}>
              <Route path="" element={<Navigate to="user" replace />} />
              <Route path="user" element={<User />} />
              <Route path="address" element={<AddressForm />} />
              <Route path="social_network" element={<LinksUser />} />
              <Route path="payments" element={<PaymentMethods />} />
            </Route>
            <Route path="general" element={<General />}>
              <Route path="" element={<Navigate to="company" replace />} />
              <Route path="company" element={<Company />} />
              <Route path="color" element={<Color />} />
              <Route path="social_networks" element={<LinksCompany />} />
            </Route>
            <Route path="users" element={<Users />}>
              <Route path="" element={<Navigate to="all" replace />} />
              <Route path="all" element={<ListUsers />} />
            </Route>
            <Route path="categories" element={<Categories />}>
              <Route path="" element={<Navigate to="all" replace />} />
              <Route path="all" element={<CategoriesInside />} />
              <Route path="detail" element={<Details />} />
            </Route>
            <Route
              path="sections/:category_id"
              element={
                <>
                  {" "}
                  <SectionPanel />
                  <Section />
                </>
              }
            ></Route>
            <Route path="section/:section_id" element={<SectionResponse />} />
            <Route path="banners" element={<BannerPanel />}>
              <Route path="" element={<Navigate to="creator" replace />} />
              <Route path="creator" element={<Banner />} />
            </Route>
          </Route>
          <Route
            path="board/:section_type/:section_id"
            element={<DesignBoard />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
