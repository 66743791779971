import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import PlusIcon from "../../assets/plus-icon.svg";
import { InfoCircleFill } from "react-bootstrap-icons";
import ButtonArrastre from "../../assets/BOTON DE ARRASTRE.svg";
import LinkIcon from "../../assets/menu-icon.svg";
import CloseIcon from "../../assets/exit-icon.svg";
import "../../ComponetStylesFlex.css";
import { MuiChipsInput } from "mui-chips-input";
import ButtonIconLow from "../../buttons/ButtonIconLow";
import styles from "./CategoriesInside.module.css";
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  changeStatusCategory,
  changeIndexCategory,
  createCategory,
  deleteCategoryById,
  getCategoriesByCompany,
} from "../../../services/company_category";
import Swal from "sweetalert2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    The keywords are used to filters the categories based on the email subject and body.
    <br />
    No keywords means that the category will be used for all the emails.
  </Tooltip>
);

function CategoriesInside() {
  const [category, setCategory] = useState();
  const [keywords, setKeywords] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    list();
  }, []);

  const list = () => {
    getCategoriesByCompany().then((response) => {
      const sortedCategories = response.data.sort((a, b) => a.index - b.index);
      setCategories(sortedCategories);
    });
  };

  const updateStatusCategory = (status, categoryId) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        return { ...category, is_active: status };
      } else {
        return category;
      }
    });
    setCategories(updatedCategories);
    changeStatusCategory(categoryId, status).then((response) => {
      if (response.status === 200) {
        list();
      }
    });
  };

  const addCategory = (e) => {
    e.preventDefault();
    const newCategory = {
      name: category,
      description: "",
      keywords: keywords || [],
    };

    createCategory(newCategory).then((response) => {
      if (response.status === 201) {
        clear();
        list();
      }
    });
  };

  const deleteCategory = (id) => {
    Swal.fire({
      title: "Atención",
      text: "¿Estás seguro que deseas eliminar esta categoría?",
      icon: "info",
      confirmButtonColor: "#28a745",
      confirmButtonText: "Aceptar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategoryById(id).then((response) => {
          if (response.status === 200) {
            list();
          }
        });
      }
    });
  };

  const clear = () => {
    setCategory("");
    setKeywords([]);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedCategories = Array.from(categories);
    const [movedCategory] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, movedCategory);
    reorderedCategories.forEach((category, index) => {
      category.index = index;
    });

    reorderedCategories.forEach((category) => {
      changeIndexCategory(category.id, category);
    });

    setCategories(reorderedCategories);
  };

  const handleChange = (newKeywords) => {
    setKeywords(newKeywords);
  };

  return (
    <div className="container-inside">
      <form onSubmit={addCategory}>
        <div className="row mt-3">
          <div className="row">
            <div className="col-2" />
            <div className="col-3">
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <h3 id="subtile-font">Category name</h3>
                </div>
              </div>
            </div>
            <div className="col-1" />
            <div className="col-3">
              <div style={{ textAlign: "center" }}>
                <OverlayTrigger
                  placement="right"
                  overlay={renderTooltip}
                >
                <h3 id="subtile-font">Keywords <InfoCircleFill /></h3>
                </OverlayTrigger>
              </div>
            </div>
            <div className="col" />
          </div>
          <div className="row">
            <div className="col-2" />
            <div className="col-3">
              <Input
                style={{
                  border: "none",
                  borderRadius: "10px",
                  height: "7vh",
                  backgroundColor: "rgba(255, 255, 255, 0.18)",
                }}
                placeholder="Category name"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </div>
            <div className="col-1" />
            <div className="col-3">
              <MuiChipsInput
                className={styles.chip}
                value={keywords}
                placeholder="Type and enter to add keywords"
                onChange={handleChange}
              />
            </div>
            <div className="col-2 d-flex">
              <ButtonIconLow
                icon={PlusIcon}
              />
            </div>
          </div>
        </div>
      </form>

      <div className="row mt-4 mb-3">
        <div className="col">
          <div className="line" />
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="categories" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={category.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="row mb-4"
                    >
                      <div className="col-1">
                        <div className="d-flex">
                          <ButtonIconLow icon={ButtonArrastre} />
                        </div>
                      </div>
                      <div className="col-4">
                        <Input
                          id="inputss"
                          value={category.name}
                          style={{
                            height: "5vh",
                          }}
                          disabled
                        />
                      </div>
                      <div className="col-4">
                        <Input
                          id="inputs-center"
                          value={category.keywords.join(", ") || ""}
                          style={{
                            height: "5vh",
                            overflowX: "scroll",
                            textAlign: "left",
                          }}
                          disabled
                        />
                      </div>
                      <div className="col-1">
                        <Link to={`/panel/sections/${category.id}`}>
                          <ButtonIconLow icon={LinkIcon} />
                        </Link>
                      </div>
                      <div className="col-2 ps-4">
                        <div className="d-flex align-items-center ">
                          <FormGroup className="switch " switch>
                            <Input
                              type="switch"
                              style={{ height: "20.8px", width: "50.4px" }}
                              role="switch"
                              checked={category.is_active}
                              onChange={(e) =>
                                updateStatusCategory(
                                  !category.is_active,
                                  category.id
                                )
                              }
                            />
                          </FormGroup>
                          <ButtonIconLow
                            expression={() => deleteCategory(category.id)}
                            icon={CloseIcon}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default CategoriesInside;
