import React, { useEffect, useState } from "react";
import { SectionRegister } from "./SectionRegister";
import { getCategoryDetails } from "services/integration";
import { SectionView } from "./SectionView";
import { useParams, useNavigate } from "react-router-dom";

const Section = () => {
  const { category_id } = useParams();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState("");

  useEffect(() => {
    listFunction();
  });

  const listFunction = () => {
    getCategoryDetails(category_id).then((response) => {
      setSections(response.data.sections);
      setCategory(response.data.name);
      setIsLoading(false);
    });
  };

  return (
    <>
      <div className="row">
        <div
          className="col-lg-10 col 12"
          style={{ textAlign: "left", fontWeight: "800", fontSize: "2rem" }}
        >
          {category}
        </div>
        <div className="col-lg-2 col 12">
          <button
            type="button"
            className={`btn btn-warning`}
            onClick={() => navigate(-1)}
            style={{ width: "100%" }}
          >
            Go back
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col 12">
          <SectionRegister
            category_id={category_id}
            listFunction={listFunction}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-9" style={{ width: "60%" }}>
          <div className="line" />
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <div>
          <div className="row justify-content-start">
            <div className="col-auto">
              <h4>
                <strong>Sections</strong>
              </h4>
            </div>
          </div>
          {sections.length === 0 ? (
            <div className="row justify-content-center">
              <div className="col-auto">
                <h6>
                  <strong>No sections registered</strong>
                </h6>
              </div>
            </div>
          ) : (
            <SectionView
              sections={sections}
              listFunction={listFunction}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Section;
