import React, { useState, useEffect } from "react";
import "../../ComponetStylesFlex.css";
import Upload from "../../assets/upload-icon.svg";
import Link from "../../assets/enlace-icon.svg";
import { Input } from "reactstrap";
import ButtonAvatar from "../../buttons/ButtonAvatar.jsx";
import Avatar from "../../buttons/Avatar";
import { useStore } from "../../../hooks/useStore";
import SaveIcon from "../../assets/save-icon.svg";
import { ThreeDots } from "react-loader-spinner";
import ButtonUploadAvatar from "../../buttons/UploadInput";
import { getUsersByCompany, updateUser } from "../../../services/company_users";
import { convertNullStringsToEmpty } from "utils/objectHelpers";

function User() {
  const { authData, setAuthData } = useStore();
  const [User, setUser] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsersByCompany().then((response) => {
      // console.log(JSON.parse(response.data));
      let user = response.data[0];
      convertNullStringsToEmpty(user);
      setUser(user);
    });
  }, []);

  const saveUser = () => {
    setLoading(true);
    setModified(false);
    const formData = new FormData();
    for (const property in User) {
      if (property !== "picture") {
        const value =
          typeof User[property] === "object"
            ? JSON.stringify(User[property])
            : User[property];
        formData.append(property, value);
      }
    }
    if (imageFile) {
      formData.append("image", imageFile);
    }
    updateUser(formData)
      .then((response) => {
        setImageFile(null);
        setLoading(false);

        if (response.status === 200) {
          localStorage.setItem(
            "AuthData",
            JSON.stringify({
              data: { company: authData.data.company, ...response.data },
            })
          );
          setAuthData({
            data: { company: authData.data.company, ...response.data },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleImageChange = (event) => {
    setModified(true);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    setUser({ ...User, picture: url });
    setImageFile(file);
  };
  return (
    <div className="row">
      <div className="col-lg-5 col-md-6 col-12 d-grid">
        <div className="col">
          <div
            className="col avatar"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar photo={User.picture} previewUrl={previewUrl} />
          </div>
        </div>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col">
            <ButtonUploadAvatar
              text={"Upload"}
              icon={Upload}
              action={handleImageChange}
            />
          </div>
          <div className="col">
            <ButtonAvatar text={"URL"} icon={Link} url={User.website} />
          </div>
        </div>
      </div>
      <div className="col-md-5 ">
        <div className="row text-start">
          <h2 id="subtile-font">Name</h2>
          <div className="col-12">
            <Input
              value={User.name || ""}
              onChange={(e) => {
                setModified(true);
                setUser({ ...User, name: e.target.value });
              }}
              style={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
                border: "none",
              }}
            />
          </div>
        </div>
        <div className="row text-start">
          <h2 id="subtile-font">Email</h2>
          <div className="col-12">
            <Input
              value={User.email || ""}
              disabled
              style={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
                border: "none",
              }}
            />
          </div>
        </div>
        <div className="row text-start mt-2">
          <h2 id="subtile-font">Description</h2>
          <div className="col-12">
            <Input
              value={User.description || ""}
              onChange={(e) => {
                setModified(true);
                setUser({ ...User, description: e.target.value });
              }}
              style={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
                border: "none",
              }}
              type="textarea"
              rows="8"
            />
          </div>
        </div>
        <div
          className="row mt-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div className="col-3">
            <ThreeDots
              // height="50%"
              width="100%"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
          </div>

          <div className="col-8">
            <div className="button-save-company ">
              <button onClick={() => saveUser()}>
                <img src={SaveIcon} alt="ToolIcon" />
                <label>Save changes</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
