import React from "react";
import paymentMethods from "./paymentMethods.json";
import './Payment.css';

function PaymentMethods() {
  return (
    <div className="payment-container">
      <h1>Payment Methods</h1>
      <div className="payment-methods row">
        {paymentMethods.map((method, index) => (
          <div className="payment-method col-md-2" key={index}>
            <img src={method.image} alt={method.name} />
            {/* <span>{method.name}</span> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PaymentMethods;
