function convertNullStringsToEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        convertNullStringsToEmpty(obj[key]); // Recursively check nested objects
      }
    }
  }
}

export { convertNullStringsToEmpty };
