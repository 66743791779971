import create from "zustand";

export const useStore = create((set) => ({
  // get the data from local storage
  authData: localStorage.getItem("authData")
    ? JSON.parse(localStorage.getItem("authData"))
    : null,

  setAuthData: (authData) => {
    localStorage.setItem("authData", JSON.stringify(authData));
    set({ authData });
  },

  bearerToken: localStorage.getItem("bearer token")
    ? JSON.parse(localStorage.getItem("bearer token"))
    : null,

  setBearerToken: (token) => {
    localStorage.setItem("bearer token", JSON.stringify(token));
    set({ token });
  },
  method: localStorage.getItem("method")
    ? JSON.parse(localStorage.getItem("method"))
    : null,

  setMethod: (method) => {
    localStorage.setItem("method", JSON.stringify(method));
    set({ method });
  },
}));
