import React from "react";
import "./buttons.css";
export default function ButtonNavbar({ icon, text }) {
  return (
    <button>
      <img src={icon} alt="ToolIcon" />
      <label>{text}</label>
    </button>
  );
}
