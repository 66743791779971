import React from "react";
import "../ComponetStylesFlex.css";
import { NavbarPanel } from "containers/NavbarPanel";
import { Outlet } from "react-router-dom";

function BannerPanel() {
  return (
    <div className="container-fluid">
      <div className="row ps-2">
        <div className="col-10">
          <NavbarPanel
            path="panel/banners"
            sections={[{ label: "Creator", path: "creator" }]}
          />
        </div>
      </div>
      <div className="col-lg-10 col 12">
        <Outlet />
      </div>
    </div>
  );
}

export default BannerPanel;
