import React from 'react'
import './buttons.css'
export default function ButtonIcon({icon,expression, disabled}) {


  return (
    <div className="button-icon">
      <button onClick={expression} disabled={disabled}>
        <img src={icon} alt="ToolIcon" />
      </button>
    </div>
  );
}
