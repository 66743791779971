import React, { Fragment, useState } from "react";
import { Form } from "react-bootstrap";

import LoginMicrosoft from "./LoginMicrosoft";
import LoginGoogle from "./LoginGoogle";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  return (
    <div
      className="d-flex justify-content-center"
      style={{ textAlign: "center" }}
    >
      <Fragment>
        <Form>
          <h1>Login</h1>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <br />
            </div>
            {/* Google login button */}

            <LoginGoogle />
            <br />
            {/* Microsoft login button */}
            <LoginMicrosoft />
          </div>
        </Form>
      </Fragment>
    </div>
  );
};
export default Login;
