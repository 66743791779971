import React from 'react'
import './buttons.css'
export default function ButtonIconShadowLabel({expression,identifier,text,}) {


  return (
    <div className="button-icon-label">
        <label  htmlFor={identifier}>{text}</label>
    </div>
  );
}
