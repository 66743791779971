import React from "react";
import { types } from "../../../constants/defaulValues";
import { BsEyeFill } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineTable } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";

import ButtonAction from "../../buttons/Action";
import { Link, useNavigate } from "react-router-dom";
import { handlerCUD } from "utils/handlerServices";
import { deleteSectionById } from "services/company_section";

const SectionView = ({ sections, setIsLoading, listFunction }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="row card mt-2"
        key={`section-base`}
        //fondo trasparente, bordes redondeados
        style={{
          background: "none",
          borderRadius: "10px",
          border: "1px solid #D9D9D9",
        }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <h5 className="card-title">Name</h5>
            </div>
            <div className="col-4">
              <h5 className="card-title">Type</h5>
            </div>
            {/* <div className="col-3">
              <h5 className="card-title">Category</h5>
            </div> */}
            <div className="col-4">
              <h5 className="card-title">Actions</h5>
            </div>
          </div>
        </div>
      </div>

      {sections.map((section, i) => (
        <div
          className="row card mt-2"
          key={`section-${i}`}
          //fondo trasparente, bordes redondeados
          style={{
            background: "none",
            borderRadius: "10px",
            border: "1px solid #D9D9D9",
          }}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-4 d-flex justify-content-start">
                <h6 className="card-title">{section.name}</h6>
              </div>
              <div className="col-4 d-flex justify-content-start">
                <h6 className="card-title">{types[section.type]}</h6>
              </div>
              {/* <div className="col-3 d-flex justify-content-start">
                <h6 className="card-title">{section.category.name}</h6>
              </div> */}
              <div className="col-4">
                <div className="Card">
                  <div className="Card_body">
                    <div className="row">
                      <div className="col-auto">
                        <ButtonAction
                          icon={<BsEyeFill />}
                          action={() => {
                            setIsLoading(true);
                            listFunction(section.id);
                          }}
                          type="primary"
                        />
                      </div>
                      <div className="col-auto">
                        <ButtonAction
                          icon={<AiOutlineTable />}
                          action={() => {
                            navigate(`/panel/section/${section.id}`);
                          }}
                          type="primary"
                        />
                      </div>
                      <div className="col-auto">
                        <ButtonAction
                          icon={<BiPencil />}
                          action={() => {
                            setIsLoading(true);
                            listFunction(section.id);
                          }}
                          type="secondary"
                        />
                      </div>
                      <div className="col-auto">
                        <Link to={`/board/${section.type}/${section.id}`}>
                          <ButtonAction
                            icon={<HiOutlineViewGrid />}
                            action={() => {}}
                            type="warning"
                          />
                        </Link>
                      </div>
                      <div className="col-auto">
                        <ButtonAction
                          icon={<AiOutlineDelete />}
                          action={() => {
                            setIsLoading(true);
                            handlerCUD(
                              deleteSectionById,
                              { id: section.id },
                              "Sección eliminada con éxito",
                              listFunction
                            );
                          }}
                          type="danger"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export { SectionView };
