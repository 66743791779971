import React, { useEffect, useState } from "react";
import "../../ComponetStylesFlex.css";
import { Input } from "reactstrap";
import PlusIcon from "../../assets/plus-icon.svg";
import SaveIcon from "../../assets/save-icon.svg";
import CloseIcon from "../../assets/exit-icon.svg";
import {
  createAddress,
  deleteAddressById,
  getAddressesByUsers,
} from "../../../services/user_address";
import ButtonIconShadowLabel from "../../buttons/ButtonIconShadowLabel";
import ButtonIconLow from "../../buttons/ButtonIconLow";
import { Country, State, City } from "country-state-city";
import Selectnew from "../../buttons/Selectnew";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import "react-phone-input-2/lib/style.css";

function AddressForm() {
  const [addresses, setAddresses] = useState([]);
  const [street, setStreet] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [cityOptions, setCityOptions] = useState([]);
  const [stateProvince, setStateProvince] = useState();
  const [stateProvinceOptions, setStateProvinceOptions] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    listAddresses();
  }, []);

  useEffect(() => {
    setStateProvince(null);
    if (country) {
      setStateProvinceOptions(
        State.getStatesOfCountry(country.value).map((province) => {
          return {
            id: province.isoCode,
            name: province.name,
          };
        })
      );
    }
    console.log(country);
  }, [country]);

  useEffect(() => {
    setCity(null);
    if (stateProvince) {
      setCityOptions(
        City.getCitiesOfState(country.value, stateProvince.value).map(
          (city) => {
            return {
              id: city.isoCode,
              name: city.name,
              postalCode: city.postalCode,
            };
          }
        )
      );
    }
  }, [country, stateProvince]);

  const countryOptions = Country.getAllCountries().map((country) => ({
    id: country.isoCode,
    name: country.name,
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    const address = {
      street,
      city: city.label,
      stateProvince: stateProvince.label,
      postalCode,
      country: country.label,
      phone,
      name,
    };
    createAddress(address).then((response) => {
      if (response.status === 201) {
        reset();
      }
    });
  };

  const inputStyle = {
    color: "white",
    border: "none",
    borderRadius: "10px",
    height: "7vh",
    backgroundColor: "rgba(255, 255, 255, 0.18)",
  };

  const deleteAddress = (id) => {
    Swal.fire({
      title: "Atención",
      text: "¿Estás seguro que deseas eliminar esta dirección?",
      icon: "info",
      confirmButtonColor: "#28a745",
      confirmButtonText: "Aceptar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAddressById(id).then((response) => {
          if (response.status === 200) {
            setAddresses((prevAddresses) =>
              prevAddresses.filter((address) => address.id !== id)
            );
          }
        });
      }
    });
  };

  const listAddresses = () => {
    getAddressesByUsers().then((response) => {
      setAddresses(response.data);
    });
  };

  const reset = () => {
    setStreet("");
    setName("");
    setCity("");
    setStateProvince("");
    setPostalCode("");
    setCountry("");
    setPhone("");
    setShowForm(false);
    listAddresses();
  };

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-lg-4 col-6">
          <div className="button-avatar">
            <button
              type="button"
              onClick={() => {
                setShowForm(!showForm);
              }}
            >
              <img src={PlusIcon} alt="ToolIcon" rel="noopener noreferrer" />
              <label>{showForm ? "Hide" : "Add address"}</label>
            </button>
          </div>
        </div>
      </div>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-md-center">
            <div className="col-lg-4 col-6">
              Address name:
              <Input
                className="custom-input"
                style={inputStyle}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-lg-4 col-6">
              Country:
              <Selectnew
                items={countryOptions}
                required={true}
                selectedOption={country}
                setSelectedOption={setCountry}
              />
            </div>
            <div className="col-lg-4 col-6">
              State/Province:
              <Selectnew
                items={stateProvinceOptions}
                required={true}
                selectedOption={stateProvince}
                setSelectedOption={setStateProvince}
                isDisabled={country ? false : true}
              />
            </div>
            <div className="col-lg-4 col-6">
              City:
              <Selectnew
                items={cityOptions}
                required={true}
                selectedOption={city}
                setSelectedOption={setCity}
                isDisabled={stateProvince ? false : true}
              />
            </div>
            <div className="col-lg-3 col-6">
              Street:
              <Input
                className="custom-input"
                style={inputStyle}
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>

            <div className="col-lg-2 col-6">
              Postal Code:
              <Input
                className="custom-input"
                style={inputStyle}
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-6">
              Phone number:
              <PhoneInput
                dropdownStyle={{ color: "black" }}
                inputStyle={inputStyle}
                country={
                  country ? country.value.toString().toLowerCase() : null
                }
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
            {/* <div className="col-lg-4 col-6"></div> */}
          </div>
          <div className="row justify-content-md-center">
            <div className="col-6 mt-2">
              <div className="button-save-company">
                <button>
                  <img src={SaveIcon} alt="ToolIcon" />
                  <label>Save changes</label>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="row mt-3">
            {addresses.map((address, index) => (
              <React.Fragment key={index}>
                <div className="col-md-2 mb-1">
                  <Input
                    style={{ height: "5vh" }}
                    id="inputss"
                    placeholder=""
                    name="input-name"
                    value={address.name}
                    disabled
                  />
                </div>

                <div className="col-md-8 mb-2 ">
                  <ButtonIconShadowLabel text={address.street} disabled />
                </div>
                <div className="col-md-1">
                  <ButtonIconLow
                    expression={() => deleteAddress(address.id)}
                    icon={CloseIcon}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressForm;
