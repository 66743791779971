import React, { useState } from "react";
import "../../ComponetStylesFlex.css";
import Upload from "../../assets/upload-icon.svg";
import Link from "../../assets/enlace-icon.svg";
import { Input } from "reactstrap";
import ButtonAvatar from "../../buttons/ButtonAvatar.jsx";
import Avatar from "../../buttons/Avatar";
import { useEffect } from "react";
import { getCompany, updateCompany } from "../../../services/company";
import { useStore } from "../../../hooks/useStore";
import SaveIcon from "../../assets/save-icon.svg";
import { ThreeDots } from "react-loader-spinner";
import ButtonUploadAvatar from "../../buttons/UploadInput";

function Company() {
  const { authData, setAuthData } = useStore();
  const [Company, setCompany] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCompany().then((response) => {
      setCompany(response.data);
    });
  }, []);

  const saveCompany = () => {

    if(!modified) return;

    setLoading(true);
    setModified(false);
    const formData = new FormData();
    for (const property in Company) {
      if (property !== "icon_image") {
        formData.append(property, Company[property]);
      }
    }
    if (imageFile) {
      formData.append("image", imageFile);
    }
    updateCompany(formData).then((response) => {
      setLoading(false);
      setImageFile(null);

      if (response.status === 200) {
        localStorage.setItem(
          "AuthData",
          JSON.stringify({ data: { ...authData.data, company: response.data } })
        );
        setAuthData({ data: { ...authData.data, company: response.data } });
      }
    });
  };
  const handleImageChange = (event) => {
    setModified(true);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    setPreviewUrl(url);
    setCompany({ ...Company, icon_image: url });
    setImageFile(file);
  };
  return (
    <div className="row">
      <div className="col-lg-5 col-md-6 col-12 d-grid">
        <div className="col">
          <div
            className="col avatar"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar photo={Company.icon_image} previewUrl={previewUrl} />
          </div>
          <div
            className="col"
            style={{ display: "flex", justifyContent: "center" }}
          ></div>
        </div>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col">
            <ButtonUploadAvatar
              text={"Upload"}
              icon={Upload}
              action={handleImageChange}
            />
          </div>
          <div className="col">
            <ButtonAvatar text={"URL"} icon={Link} url={Company.website} />
          </div>
        </div>
      </div>
      <div className="col-md-5 ">
        <div className="row text-start">
          <h2 id="subtile-font">Company Name</h2>
          <div className="col-12">
            <Input
              value={Company.name}
              onChange={(e) => {
                setModified(true);
                setCompany({ ...Company, name: e.target.value });
              }}
              style={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
                border: "none",
              }}
            />
          </div>
        </div>
        <div className="row text-start">
          <h2 id="subtile-font">Company Domain</h2>
          <div className="col-12">
            <Input
              value={Company.domain}
              disabled
              style={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
                border: "none",
              }}
            />
          </div>
        </div>
        <div className="row text-start mt-2">
          <h2 id="subtile-font">Description</h2>
          <div className="col-12">
            <Input
              value={Company.description}
              onChange={(e) => {
                setModified(true);
                setCompany({ ...Company, description: e.target.value });
              }}
              style={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
                border: "none",
              }}
              type="textarea"
              rows="8"
            />
          </div>
        </div>
        <div
          className="row mt-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div className="col-3">
            <ThreeDots
              // height="50%"
              width="100%"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
          </div>

          <div className="col-8">
            <div className="button-save-company ">
              <button onClick={() => saveCompany()}>
                <img src={SaveIcon} alt="ToolIcon" />
                <label>Save changes</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
