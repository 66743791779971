import React from 'react'
import './buttons.css'
export default function ButtonIconLow({icon,expression}) {


  return (
    <div className="button-icon">
      <button onClick={expression}>
        <img className='img-low'  src={icon} alt="ToolIcon" />
      </button>
    </div>
  );
}
