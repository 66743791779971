import React, { useContext } from "react";
import { useDrop } from "react-dnd";
import { Element } from "./Element";
import { BoardContext } from "providers/boardProvider";

const BlankBoard = () => {
  const { elements, moveElement, boardWidth, boardHeight, changeHeight } =
    useContext(BoardContext);

  const [{ isOver }, drop] = useDrop({
    accept: "element",
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x);
      const top = Math.round(item.top + delta.y);
      moveElement(item.id, left, top);
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
      <div
        ref={drop}
        style={{
          width: `${boardWidth}px`,
          height: `${boardHeight}px`,
          backgroundColor: "white",
          margin: "0 auto",
          position: "relative",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {elements.map((element) => (
          <Element
            key={element.id + element.type}
            left={element.left}
            top={element.top}
            field={element}
          />
        ))}
        {isOver && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              opacity: 0.5,
              backgroundColor: "lightblue",
            }}
          />
        )}
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={() => changeHeight(boardHeight + 100)}
          >
            Extend Board
          </button>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary"
            onClick={() => changeHeight(boardHeight - 100)}
          >
            Reduce Board
          </button>
        </div>
      </div>
    </>
  );
};

export { BlankBoard };
