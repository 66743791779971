import React from "react";
import "../ComponetStylesFlex.css";
import PreviewIcon from "../assets/preview-icon.svg";
import PublishIcon from "../assets/send-icon.svg";
import Logout from "../assets/logout.svg";
import ButtonNavbar from "../buttons/ButtonNavbar";
import { useStore } from "../../hooks/useStore";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const { authData, setAuthData } = useStore();
  const navigate = useNavigate();

  return (
    <>
      {authData && (
        <>
          <div className="col-md-3 text-start">
            <h2 id="title">{authData.data.company.name}</h2>
          </div>
          <div className="col-md-8 ms-2 my-1  d-flex justify-content-end">
            <div className="col-lg-3 mx-1 button-navbar">
              <ButtonNavbar icon={PreviewIcon} text={"Preview page "} />
            </div>
            <div className="col-lg-3 mx-1 button-navbar">
              <ButtonNavbar icon={PublishIcon} text={"     Publish      "} />
            </div>
            <div className="col-lg-2 mx-1 button-navbar d-flex">
              <button
                onClick={() => {
                  googleLogout();
                  localStorage.clear();
                  setAuthData(null);
                  navigate("/");
                }}
              >
                <img src={Logout} alt="ToolIcon" />
                <label>Salir</label>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
