import React from "react";
import "../ComponetStylesFlex.css";
import { NavbarPanel } from "containers/NavbarPanel";
import { Outlet } from "react-router-dom";

function Users() {
  return (
    <div className="container-fluid">
      <div className="row ps-2">
        <div className="row">
          <NavbarPanel
            path="/panel/users"
            sections={[{ label: "Users", route: "all" }]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col 12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Users;
