import React from "react";
import Select from "react-select";

export default function Selectnew({
  items,
  required,
  selectedOption,
  setSelectedOption,
  isDisabled
}) {
  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const Icon = () => {
    return (
      <svg height="15" width="15" viewBox="0 0 20 60">
        <path d="M21.37,0H2.15A2.15,2.15,0,0,0,.29,3.22L9.9,19.86a2.14,2.14,0,0,0,3.71,0L23.22,3.22A2.14,2.14,0,0,0,21.37,0Z"></path>
      </svg>
    );
  };
  const options = items.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      height: "7vh",
      background: "rgba(255, 255, 255, 0.18)",
      borderRadius: state.isFocused ? "10px 10px 0 0" : 10,
      borderColor: "transparent",
      "&:hover": {
        borderColor: state.isFocused ? "white" : "white",
      },
      // ...(required && {border: '1px solid red', isRequired: true}),
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 5,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "rgba(255, 255, 255, 0.18)", // o cualquier otro color deseado
      };
    },
    singleValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "white", // Cambiar el color del texto en el campo seleccionado
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        color: isFocused ? "black" : "black",
      };
    },
  };

  return (
    <div className="col">
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
        components={{ DropdownIndicator: Icon }}
        placeholder="Choose an option"
        styles={customStyles}
        required={required}
        isDisabled={isDisabled}
      />
    </div>
  );
}
