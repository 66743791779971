import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";
const baseUrl = urlBase;

export const getAddressesByUsers = () => {
  return axios.get(`${baseUrl}/user-address/get-by-user`);
};

export const createAddress = (address) => {
  return axios.post(`${baseUrl}/user-address/create`, address);
};

export const updateAddreess = (address) => {
  return axios.put(`${baseUrl}/user-address/update`, address, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteAddressById = (id) => {
  return axios.delete(`${baseUrl}/user-address/delete/${id}`);
};
