import React from "react";
import "./buttons.css";
export default function ButtonAvatar({ icon, text, url }) {
  const handleClick = () => {
    if (!url) return;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="button-avatar">
      <button onClick={handleClick} disabled={!url}>
        <img src={icon} alt="ToolIcon" rel="noopener noreferrer" />
        <label>{text}</label>
      </button>
    </div>
  );
}
