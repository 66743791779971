import React, { useRef } from "react";
import "./buttons.css";

export default function ButtonUploadAvatar({ icon, text, action }) {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="button-avatar">
      <button onClick={handleButtonClick} type="button">
        <img
          src={icon}
          htmlFor="avatar-upload"
          alt="ToolIcon"
          rel="noopener noreferrer"
        />
        <label>{text}</label>
      </button>
      <input
        ref={fileInputRef}
        id="avatar-upload"
        type="file"
        accept="image/*"
        onChange={action}
        hidden
      />
    </div>
  );
}
