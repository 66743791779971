import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";

const baseUrl = urlBase;

export const getBannersByCompany = () => {
  return axios.get(`${baseUrl}/company-banner/get-by-company`);
};

export const createBanner = (banner) => {
  return axios.post(`${baseUrl}/company-banner/create`, banner);
};

export const changeStatusBanner = (id, is_active) => {
  return axios.put(`${baseUrl}/company-banner/update/${id}`, { is_active });
};

export const deleteBannerById = (id) => {
  return axios.delete(`${baseUrl}/company-banner/delete/${id}`);
};
