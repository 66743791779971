import React from "react";

const StaticOptions = ({ register, fieldType }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <label style={{ color: "black" }} htmlFor="name">
            Value
          </label>
          {fieldType === "textarea" && (
            <textarea
              className="form-control"
              id="local"
              placeholder="Value"
              {...register("local")}
              cols={40}
              rows={5}
              style={{
                fontSize: "12px", 
              }}
            />
          )}
          {fieldType === "text" && (
            <input
              type="text"
              className="form-control"
              id="local"
              placeholder="Value"
              {...register("local")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export { StaticOptions };
