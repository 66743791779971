import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";

const baseUrl = urlBase;

export const getAllColors = () => {
  return axios.get(`${baseUrl}/company-color/get-all`);
};

export const getColorsByCompany = () => {
  return axios.get(`${baseUrl}/company-color/get-by-company`);
};

export const createColor = (color) => {
  return axios.post(`${baseUrl}/company-color/create`, color);
};
export const deleteColorById = (id) => {
  return axios.delete(`${baseUrl}/company-color/delete/${id}`);
};
