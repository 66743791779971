/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ServiceOptions } from "./ServiceOptions";
import { StaticOptions } from "./StaticOptions";
import { FormOptions } from "./FormOptions";

const ElementOptions = ({ field, updateElement, handleClickOutside }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    unregister,
    // formState: { errors },
  } = useForm();

  const [isService, setIsService] = useState(false);
  const [isStatic, setIsStatic] = useState(false);

  const changeToService = () => {
    setIsService(true);
    setIsStatic(false);
    unregister("local");
  };

  const changeToStatic = () => {
    setIsService(false);
    setIsStatic(true);
    unregister("endpoint");
    unregister("token");
    unregister("access");
  };

  useEffect(() => {
    reset(field.value);
    if (field.value?.local) {
      setIsStatic(true);
    } else if (field.value?.endpoint) {
      setIsService(true);
    }
  }, []);

  const handleSave = (data) => {
    console.log(data);
    updateElement(field.id, data);
    handleClickOutside();
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="row justify-content-end">
          <div className="col-auto">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={handleClickOutside}
            />
          </div>
        </div>
        {field.element_type === "static" && (
          <>
            <div className="row mt-2">
              <div className="col">
                <button
                  type="button"
                  className={`btn btn-${
                    isService ? "primary" : "secondary"
                  } btn-sm`}
                  onClick={changeToService}
                >
                  Consume service
                </button>
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  className={`btn btn-${
                    isStatic ? "primary" : "secondary"
                  } btn-sm`}
                  onClick={changeToStatic}
                >
                  Write values
                </button>
              </div>
            </div>
          </>
        )}
        {field.element_type === "form" && (
          <FormOptions
            register={register}
            fieldType={field.field_type}
            control={control}
          />
        )}
        {isService && field.element_type !== "form" && (
          <ServiceOptions register={register} />
        )}
        {isStatic && (
          <StaticOptions register={register} fieldType={field.field_type} />
        )}
        {(isService || isStatic || field.element_type === "form") && (
          <div className="row mt-2 justify-content-center">
            <div className="col-auto">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export { ElementOptions };
