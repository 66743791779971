import React from 'react'
import './buttons.css'
export default function ButtonSideBar({icon,text,expression,className}) {


  return (
    <div onClick={expression} className={className}>
      <img src={icon} alt="ToolIcon" />
      <label>{text}</label>
    </div>
  );
}
