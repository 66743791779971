/* eslint-disable react/jsx-no-duplicate-props */
import { v4 as uuid } from "uuid";

export const fields = [
  {
    element_type: "static",
    id: "title",
    type: "title",
    field_type: "text",
    name: "Title",
    styles: {
      color: "black",
      fontSize: "20px",
      fontWeight: "bold",
      textAlign: "center",
      textDecoration: "none",
      fontStyle: "normal",
      fontFamily: "Arial",
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    value: null,
    length: 125,
    left: 25,
    top: 25,
    width: 450,
    height: 50,
  },
  {
    element_type: "static",
    id: "subtitle",
    type: "subtitle",
    field_type: "text",
    name: "Subtitle",
    styles: {
      color: "black",
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "center",
      textDecoration: "none",
      fontStyle: "normal",
      fontFamily: "Arial",
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    value: null,
    length: 125,
    left: 25,
    top: 25,
    width: 450,
    height: 50,
  },
  {
    element_type: "static",
    id: "paragraph",
    type: "paragraph",
    field_type: "textarea",
    name: "Paragraph",
    styles: {
      color: "black",
      fontSize: "12px",
      fontWeight: "normal",
      textAlign: "left",
      textDecoration: "none",
      fontStyle: "normal",
      fontFamily: "Arial",
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    value: null,
    length: 250,
    left: 25,
    top: 25,
    width: 450,
    height: 80,
  },
  {
    element_type: "static",
    id: "image",
    type: "image",
    field_type: "file",
    name: "Image",
    value: null,
    left: 25,
    top: 25,
    width: 450,
    height: 50,
  },
];

export const formFields = [
  {
    element_type: "form",
    id: "input-text",
    type: "input-text",
    field_type: "text",
    name: "Text",
    value: {
      label: "Label",
    },
    length: 125,
    left: 25,
    top: 25,
    width: 450,
    height: 65,
  },
  {
    element_type: "form",
    id: "input-large",
    type: "input-large",
    field_type: "textarea",
    name: "Large",
    value: {
      label: "Label",
    },
    length: 250,
    left: 25,
    top: 25,
    width: 450,
    height: 90,
  },
  {
    element_type: "form",
    id: "input-number",
    type: "input-number",
    field_type: "number",
    name: "Number",
    value: {
      label: "Label",
    },
    left: 25,
    top: 25,
    width: 450,
    height: 65,
  },
  {
    element_type: "form",
    id: "input-date",
    type: "input-date",
    field_type: "date",
    name: "Date",
    value: {
      label: "Label",
    },
    left: 25,
    top: 25,
    width: 450,
    height: 65,
  },
  // {
  //   element_type: "form",
  //   id: "input-image",
  //   type: "input",
  //   field_type: "file",
  //   name: "image",
  //   value: {
  //     label: "Label",
  //   },
  //   left: 25,
  //   top: 25,
  //   width: 450,
  //   height: 65,
  // },
  // {
  //   element_type: "form",
  //   id: "input-file",
  //   type: "input",
  //   field_type: "file",
  //   name: "file",
  //   value: {
  //     label: "Label",
  //   },
  //   left: 25,
  //   top: 25,
  //   width: 450,
  //   height: 65,
  // },
  {
    element_type: "form",
    id: "select",
    type: "single-select",
    field_type: "select",
    name: "Single Select",
    value: {
      label: "Label",
    },
    left: 25,
    top: 25,
    width: 450,
    height: 65,
  },
  {
    element_type: "form",
    id: "input-checkbox",
    type: "multi-select",
    field_type: "checkbox",
    name: "Multi Select",
    value: {
      label: "Label",
    },
    left: 25,
    top: 25,
    width: 450,
    height: 65,
  },
  {
    element_type: "form",
    id: "button",
    type: "button",
    field_type: "button",
    name: "Button",
    value: {
      label: "Submit",
    },
    left: 25,
    top: 25,
    width: 450,
    height: 30,
  },
];

export const findfield = (field) => {
  if (
    field.type === "input-text" ||
    field.type === "input-number" ||
    field.type === "input-date"
  ) {
    return (
      <div className="form-group">
        <label
          style={{ color: "black", textAlign: "left" }}
          key={field.id + uuid()}
          htmlFor={field.id}
        >
          {field.value.label}
        </label>
        <input
          className="form-control"
          key={field.id + uuid()}
          name={field.name}
          type={field.type}
          disabled
        />
      </div>
    );
  } else if (field.type === "input-large") {
    return (
      <>
        <label
          className="form-label"
          style={{ color: "black" }}
          htmlFor={field.id}
        >
          {field.value.label}
        </label>
        <textarea
          className="form-control"
          key={field.id + uuid()}
          name={field.name}
          disabled
        />
      </>
    );
  } else if (field.type === "single-select") {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          padding: "10px",
        }}
      >
        <label htmlFor={field.id} style={{ color: "black" }}>
          {field.value?.label}
        </label>
        {field.value?.options?.map((option, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
            }}
            className="form-check"
          >
            <input
              className="form-check-input"
              type="radio"
              name={option.name}
              value={option.value}
              disabled
            />
            <label className="form-label" style={{ color: "black" }}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    );
  } else if (field.type === "multi-select") {
    return (
      <div
        className="form-group"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          padding: "10px",
        }}
      >
        <label htmlFor={field.id} style={{ color: "black" }}>
          {field.value?.label}
        </label>
        {field.value?.options?.map((option, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
            }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              name={option.name}
              value={option.value}
              disabled
            />
            <label className="form-label" style={{ color: "black" }}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    );
  } else if (field.type === "button") {
    return (
      <button
        className="btn btn-primary"
        key={field.id + uuid()}
        name={field.name}
        type={field.type}
        disabled
      >
        {field.value.label}
      </button>
    );
  }

  if (field.type === "title") {
    return (
      <h1
        className="form-control"
        key={field.id + uuid()}
        name={field.name}
        style={field.styles}
      >
        {field.value?.local}
      </h1>
    );
  }
  if (field.type === "subtitle") {
    return (
      <h2
        className="form-control"
        key={field.id + uuid()}
        name={field.name}
        style={field.styles}
      >
        {field.value?.local}
      </h2>
    );
  }
  if (field.type === "paragraph") {
    return (
      <p
        className="form-control"
        key={field.id + uuid()}
        name={field.name}
        style={{
          wordWrap: "break-word",
          ...field.styles,
        }}
      >
        {field.value?.local}
      </p>
    );
  }
  if (field.type === "image") {
    return (
      <img
        className="form-control"
        key={field.id + uuid()}
        name={field.name}
        src={field?.value?.endpoint}
        alt={field.name}
      ></img>
    );
  }
};
