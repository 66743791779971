import React, { useContext } from "react";
import { fields, formFields } from "./Fields";
import { BoardContext } from "providers/boardProvider";
import { types } from "constants/defaulValues";

/**
 * Creates a list of elements to add to the board
 * @param {*} addFunction
 * @returns
 */
const AddElement = () => {
  const { addElement, sectionType } = useContext(BoardContext);

  return (
    <div className="container">
      <div className="row mt-1 justify-content-center">
        <div className="col-auto">
          <h3>Static</h3>
        </div>
      </div>
      {fields.map((field, i) => (
        <div className="row mt-1" key={`${field.id}-${i}`}>
          <div className="col">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => addElement(field)}
              style={{ width: "100%" }}
            >
              {field.name}
            </button>
          </div>
        </div>
      ))}
      {sectionType !== types.read && (
        <>
          <div className="row mt-1 justify-content-center">
            <div className="col-auto">
              <h3>Forms</h3>
            </div>
          </div>

          {formFields.map((field, i) => (
            <div className="row mt-1" key={`${field.id}-${i}`}>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => addElement(field)}
                  style={{ width: "100%" }}
                >
                  {field.name}
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AddElement;
