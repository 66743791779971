import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";

const baseUrl = urlBase;

export const getCategoriesByCompany = () => {
  return axios.get(`${baseUrl}/company-category/get-by-company`);
};

export const createCategory = (category) => {
  return axios.post(`${baseUrl}/company-category/create`, category);
};
export const changeStatusCategory = (id, is_active) => {
  return axios.put(`${baseUrl}/company-category/update/${id}`, { is_active });
};
export const changeIndexCategory = (id, body) => {
  return axios.put(`${baseUrl}/company-category/update/${id}`, { body });
};
export const deleteCategoryById = (id) => {
  return axios.delete(`${baseUrl}/company-category/delete/${id}`);
};
