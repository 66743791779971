import React, { useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useStore } from "../../hooks/useStore";
import { loginMicrosoft } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import microsoftButton from "../assets/microsoftButton.svg";

const LoginMicrosoft = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    },
  };
  const setAuthData = useStore((state) => state.setAuthData);
  const setMethod = useStore((state) => state.setMethod);
  const setBearerToken = useStore((state) => state.setBearerToken);
  const msalInstance = new PublicClientApplication(msalConfig);

  const handleLogin = async () => {
    try {
      var loginRequest = {
        scopes: ["user.read"] // optional Array<string>
      };
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      localStorage.setItem(
        "bearer token",
        JSON.stringify(loginResponse.accessToken)
      );
      localStorage.setItem("method", "microsoft");
      setMethod("microsoft");
      setBearerToken(loginResponse.accessToken);
      loginMicrosoft(loginResponse.accessToken).then((response) => {
        if (response.status === 200) {
          localStorage.setItem("AuthData", JSON.stringify(response.data));
          setAuthData(response.data);

          navigate("/panel");
        }
      });
    } catch (error) {
      console.log("Login error:", error);
    }
  };

  const handleLogout = () => {
    msalInstance.logout();
    setAccessToken("");
  };

  return (
    <div>
      {accessToken ? (
        <div>
          <p>Access Token: {accessToken}</p>
          <button type="button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      ) : (
        <button
          type="button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            color: "black",
            border: "none",
            padding: "10px 20px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={handleLogin}
          className="login-button"
        >
          <img
            style={{
              width: "20px" /* Adjust the width and height to fit your logo */,
              height: " 20px",
              marginRight: "8px",
            }}
            src={microsoftButton}
            alt="Microsoft Logo"
            className="microsoft-logo"
          />
          Login with Microsoft
        </button>
      )}
    </div>
  );
};

export default LoginMicrosoft;
