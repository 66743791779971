import React from "react";
import { SelectOptions } from "./SelectOptions";

const FormOptions = ({ register, fieldType, control }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <label
            className="form-label"
            style={{ color: "black" }}
            htmlFor="label"
          >
            Label
          </label>
          <input
            type="text"
            className="form-control"
            id="label"
            placeholder="Label"
            {...register("label")}
          />
        </div>
      </div>
      <div className="row justify-content-start mt-2">
        <div className="col-auto">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkMandatory"
              {...register("mandatory")}
            />
            <label
              className="form-check-label"
              style={{ color: "black" }}
              for="checkMandatory"
            >
              Mandatory
            </label>
          </div>
        </div>
      </div>
      {(fieldType === "select" || fieldType === "checkbox") && (
        <SelectOptions register={register} control={control} />
      )}
      {fieldType === "button" && (
        <>
          <div className="row">
            <div className="col">
              <label style={{ color: "black" }} htmlFor="endpoint">
                Endpoint
              </label>
              <input
                type="text"
                className="form-control"
                id="endpoint"
                placeholder="Endpoint"
                {...register("endpoint")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label style={{ color: "black" }} htmlFor="token">
                Token
              </label>
              <input
                type="text"
                className="form-control"
                id="token"
                placeholder="Token"
                {...register("token")}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { FormOptions };
