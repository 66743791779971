import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import PlusIcon from "../../assets/plus-icon.svg";
import CloseIcon from "../../assets/exit-icon.svg";
import "../../ComponetStylesFlex.css";
import ButtonIcon from "../../buttons/ButtonIcon";
import ButtonArraste from "../../assets/BOTON DE ARRASTRE.svg";
import ButtonIconLow from "../../buttons/ButtonIconLow";
import {
  changeStatusBanner,
  createBanner,
  deleteBannerById,
  getBannersByCompany,
} from "../../../services/company_banner";
function Banner() {
  const [banners, SetBanners] = useState([]);

  useEffect(() => {
    list();
  }, []);

  const list = () => {
    getBannersByCompany().then((response) => {
      SetBanners(response.data);
    });
  };

  const updateStatusBanner = (status, bannerId) => {
    const updatedBanners = banners.map((banner) => {
      if (banner.id === bannerId) {
        return { ...banner, is_active: status };
      } else {
        return banner;
      }
    });
    SetBanners(updatedBanners);
    changeStatusBanner(bannerId, status).then((response) => {
      if (response.status === 200) {
        list();
      }
    });
  };

  const [banner, setBanner] = useState({
    name: "",
    url: "",
    image: "",
  });

  const addBanner = (e) => {
    e.preventDefault();

    const newBanner = {
      ...banner,
    };

    createBanner(newBanner).then((response) => {
      if (response.status === 201) {
        clear();
        list();
      }
    });
  };

  const deleteBanner = (id) => {
    deleteBannerById(id).then((response) => {
      if (response.status === 200) {
        list();
      }
    });
  };

  const clear = () => {
    setBanner({ name: "", image: "", url: "" });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-10 mt-3 mb-3" style={{ width: "1030px" }}>
          <div className="line" />
        </div>
      </div>
      <form onSubmit={addBanner}>
        <div className="row">
          <div className="col-lg-3 text-start">
            <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
              Banner name
            </h3>
            <Input
              style={{
                border: "none",
                borderRadius: "10px",
                height: "7vh",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
              }}
              value={banner.name}
              onChange={(e) => setBanner({ ...banner, name: e.target.value })}
              required
            />
          </div>
          <div className="col-lg-3 col-3 col-sm-6 text-start">
            <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
              URL
            </h3>
            <Input
              style={{
                border: "none",
                borderRadius: "10px",
                height: "7vh",
                backgroundColor: "rgba(255, 255, 255, 0.18)",
              }}
              value={banner.url}
              onChange={(e) => setBanner({ ...banner, url: e.target.value })}
              required
            />
          </div>
          <div className="col-6 text-start ps-4 pe-5">
            <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
              Banner image
            </h3>

            <div className="row ">
              <div className="col-8 ">
                <Input
                  placeholder="URL"
                  style={{
                    border: "none",
                    borderRadius: "10px",
                    height: "7vh",
                    backgroundColor: "rgba(255, 255, 255, 0.18)",
                  }}
                  value={banner.image}
                  onChange={(e) =>
                    setBanner({ ...banner, image: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col-1 mx-0">
                <ButtonIcon icon={PlusIcon} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-10 mt-4 mb-4" style={{ width: "1030px" }}>
          <div className="line" />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-1">
          <h4 id="subtile-font">Order</h4>
        </div>
        <div className="col-3">
          <h4 id="subtile-font">Name</h4>
        </div>
        <div className="col-3">
          <h4 id="subtile-font">URL</h4>
        </div>
        <div className="col-1">
          <h4 id="subtile-font">Time</h4>
        </div>
        <div className="col-1">
          <h4 id="subtile-font">Status</h4>
        </div>
      </div>

      {banners.map((banner, index) => (
        <div className="row  mb-2">
          <div className="col-1">
            <div className="d-flex">
              <ButtonIconLow icon={ButtonArraste} />
            </div>
          </div>
          <div className="col-3 text-start">
            <Input
              style={{ height: "5vh" }}
              id="inputss-white"
              value={banner.name}
              disabled
            />
          </div>
          <div className="col-3 text-start">
            <Input
              style={{ height: "5vh" }}
              id="inputss-white"
              value={banner.url}
              disabled
            />
          </div>
          <div className="col-2 text-start">
            <Input
              style={{ height: "5vh" }}
              id="inputss-white"
              value={banner.duration}
              disabled
            />
          </div>
          <div className="col-1">
            <div className="d-flex align-items-center">
              <FormGroup className="switch" switch>
                <Input
                  type="switch"
                  style={{ height: "20.8px", width: "50.4px" }}
                  role="switch"
                  checked={banner.is_active}
                  onChange={(e) =>
                    updateStatusBanner(!banner.is_active, banner.id)
                  }
                />
              </FormGroup>
              <div className="ms-3">
                <ButtonIconLow
                  expression={() => deleteBanner(banner.id)}
                  icon={CloseIcon}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Banner;
