import React, { useState } from "react";
import { CustomInput } from "../../fields/input";
import { CustomSelect } from "../../fields/select";
import ButtonIconLow from "../../buttons/ButtonIconLow";
import PlusIcon from "../../assets/plus-icon.svg";
import { createSection } from "../../../services/company_section";
// import { getCategoriessByCompany } from "services/company_category";
// import { selectable } from "utils/selectable";
import { typeSections } from "constants/defaulValues";

const SectionRegister = ({ listFunction, setIsLoading, category_id }) => {
  const [nameSection, setNameSection] = useState("");

  const [selectedTypeSection, setSelectedTypeSection] = useState(null);

  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   getCategoriessByCompany().then((response) => {
  //     const selectables = selectable(response.data, "id", "name");
  //     setCategories(selectables);
  //   });
  // }, []);

  const addSection = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const section = {
      name: nameSection,
      type: selectedTypeSection,
      category: category_id,
    };
    createSection(section)
      .then((response) => {
        listFunction();
        setNameSection("");
        setSelectedTypeSection(null);
      })
      .catch((error) => {});
  };

  return (
    <div className="container-inside">
      <form onSubmit={addSection}>
        <div className="row mt-3">
          <div className="col-5">
            <CustomInput
              label="Section name"
              value={nameSection}
              setFunction={setNameSection}
              required
            />
          </div>
          <div className="col-5">
            <CustomSelect
              label="Section type"
              options={typeSections}
              value={selectedTypeSection}
              setValue={setSelectedTypeSection}
              required={true}
            />
          </div>
          {/* <div className="col-4">
            <CustomSelect
              label="Category"
              options={categories}
              value={selectedCategory}
              setValue={setSelectedCategory}
              required={true}
            />
          </div> */}
          <div className="col-1 d-flex mt-4">
            <button
              type="submit"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                margin: 0,
              }}
            >
              <ButtonIconLow icon={PlusIcon} />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { SectionRegister };
