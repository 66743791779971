import React from "react";
import { useStore } from "../../hooks/useStore";
import { loginGoogle } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const LoginGoogle = () => {
  const navigate = useNavigate();
  const setAuthData = useStore((state) => state.setAuthData);
  const setMethod = useStore((state) => state.setMethod);
  const setBearerToken = useStore((state) => state.setBearerToken);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        useOneTap={true}
        size="large"
        onSuccess={async (credentialResponse) => {
          localStorage.setItem(
            "bearer token",
            JSON.stringify(credentialResponse.credential)
          );
          localStorage.setItem("method", "google");
          setMethod("google");

          setBearerToken(credentialResponse.credential);
          loginGoogle(credentialResponse.credential).then((response) => {
            if (response.status === 200) {
              localStorage.setItem("AuthData", JSON.stringify(response.data));
              setAuthData(response.data);

              navigate("/panel");
            }
          });
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default LoginGoogle;
