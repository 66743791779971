import { BoardContext } from "providers/boardProvider";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Actions = () => {
  const navigate = useNavigate();

  const {
    deleteAction,
    changeDeleteAction,
    previewAction,
    changePreviewAction,
    saveChanges,
  } = useContext(BoardContext);

  return (
    <div>
      <h3>Actions</h3>
      <div className="container">
        <div className="row mt-1">
          <div className="col">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={saveChanges}
            >
              Save
            </button>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <button
              type="button"
              className={`btn btn-${deleteAction ? "danger" : "primary"}`}
              onClick={changeDeleteAction}
              style={{ width: "100%" }}
            >
              {deleteAction ? "Finish" : "Delete"}
            </button>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col">
            <button
              type="button"
              className={`btn btn-${previewAction ? "danger" : "primary"}`}
              onClick={changePreviewAction}
              style={{ width: "100%" }}
            >
              {previewAction ? "Finish" : "Preview"}
            </button>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col">
            <button
              type="button"
              className={`btn btn-warning`}
              onClick={() => navigate(-1)}
              style={{ width: "100%" }}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Actions };
