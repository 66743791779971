import React from "react";
import { Input } from "reactstrap";

const CustomSelect = ({
  label,
  options,
  value,
  setValue,
  required = false,
}) => {
  return (
    <>
      <div className="row">
        <div style={{ textAlign: "left" }}>
          <h3 id="subtile-font">{label}</h3>
        </div>
      </div>
      <Input
        style={{
          border: "none",
          borderRadius: "10px",
          height: "7vh",
          backgroundColor: "rgba(255, 255, 255, 0.18)",
          color: "white",
        }}
        type="select"
        name="select"
        value={!value ? "" : value}
        onChangeCapture={(e) => setValue(e.target.value)}
        required={required}
      >
        <option
          key={0}
          value=""
          disabled
          hidden
        >
          Select an option
        </option>
        {options.map((option) => (
          <option
            style={{
              border: "none",
              borderRadius: "10px",
              height: "7vh",
              backgroundColor: "black",
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Input>
    </>
  );
};

export { CustomSelect };
