import React from "react";
import "../ComponetStylesFlex.css";
import { NavbarPanel } from "containers/NavbarPanel";
import { Outlet } from "react-router-dom";

function General() {
  return (
    <div className="container-fluid">
      <div className="row"></div>
      <div className="row">
        <NavbarPanel
          path="/panel/general"
          sections={[
            { label: "Company", route: "company" },
            { label: "Color", route: "color" },
            { label: "Links", route: "social_networks" },
          ]}
        />
      </div>

      <div className="row">
        <div className="col-9">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col 12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default General;
