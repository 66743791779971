import React from "react";

const ServiceOptions = ({ register }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <label style={{ color: "black" }} htmlFor="endpoint">
            Url
          </label>
          <input
            type="text"
            className="form-control"
            id="endpoint"
            placeholder="Endpoint"
            {...register("endpoint")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label style={{ color: "black" }} htmlFor="token">
            Token
          </label>
          <input
            type="text"
            className="form-control"
            id="token"
            placeholder="Token"
            {...register("token")}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label style={{ color: "black" }} htmlFor="access">
            Access response
          </label>
          <input
            type="text"
            className="form-control"
            id="access"
            placeholder="Access response"
            {...register("access")}
          />
        </div>
      </div>
    </>
  );
};

export { ServiceOptions };
