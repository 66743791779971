import React from "react";
import "../ComponetStylesFlex.css";
import { Outlet } from "react-router-dom";
import { NavbarPanel } from "../../containers/NavbarPanel";

function Profile() {
  return (
    <div className="container-fluid">
      <div className="row"></div>
      <div className="row">
        <NavbarPanel
          path={"/panel/profile"}
          sections={[
            { label: "Profile", route: "user" },
            { label: "Addresses", route: "address" },
            { label: "Payments", route: "payments" },
            { label: "Links", route: "social_network" },
          ]}
        />
      </div>

      <div className="row">
        <div className="col-9">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col 12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Profile;
