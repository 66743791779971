import React from "react";
import { Input } from "reactstrap";

const CustomInput = ({ label, value, setFunction, required = false }) => {
  return (
    <>
      <div className="row">
        <div style={{ textAlign: "left" }}>
          <h3 id="subtile-font">{label}</h3>
        </div>
      </div>
      <Input
        style={{
          border: "none",
          borderRadius: "10px",
          height: "7vh",
          backgroundColor: "rgba(255, 255, 255, 0.18)",
          color: "white",
        }}
        value={value}
        onChange={(e) => setFunction(e.target.value)}
        required={required}
      />
    </>
  );
};

export { CustomInput };
