import React from "react";
import { useFieldArray } from "react-hook-form";

const SelectOptions = ({ register, control }) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "options",
  });

  const addOption = () => {
    append({
      label: "",
      value: "",
    }); // Agregar una nueva opción con un campo "name" vacío
  };

  const handleRemoveOption = (index) => {
    remove(index); // Eliminar la opción en el índice especificado
  };

  const handleMoveOptionUp = (index) => {
    move(index, index - 1); // Mover la opción hacia arriba
  };

  const handleMoveOptionDown = (index) => {
    if (index === fields.length - 1) return; // Si es la última opción, no hacer nada
    move(index, index + 1); // Mover la opción hacia abajo
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={addOption}
          >
            Agregar opción +
          </button>
        </div>
      </div>
      {fields.map((option, index) => (
        <div
          key={option.id}
          className="row mt-1"
          style={{
            border: "1px solid grey",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          <div className="col-1">
            <div className="row">
              <button
                type="button"
                className="btn btn-ligth btn-sm"
                onClick={() => handleMoveOptionUp(index)}
              >
                ↑
              </button>
            </div>
            <div className="row">
              <button
                type="button"
                className="btn btn-ligth btn-sm"
                onClick={() => handleMoveOptionDown(index)}
              >
                ↓
              </button>
            </div>
          </div>
          <div className="col-5">
            <label
              className="form-label"
              style={{ color: "black", fontSize: "12px" }}
              htmlFor="label"
            >
              Label
            </label>
            <input
              id="label"
              className="form-control"
              type="text"
              {...register(`options.${index}.label`)} // Usar la notación "options[index].name" para registrar el campo
            />
          </div>
          <div className="col-5">
            <label
              className="form-label"
              style={{ color: "black", fontSize: "12px" }}
              htmlFor="value"
            >
              Value
            </label>
            <input
              id="value"
              className="form-control"
              type="text"
              {...register(`options.${index}.value`)} // Usar la notación "options[index].name" para registrar el campo
            />
          </div>
          <div className="col-1 mt-4">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleRemoveOption(index)}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export { SelectOptions };
