import axios from "axios";
import { getBearerToken, getMethod } from "../helpers/Utils";

axios.interceptors.request.use(
  (config) => {
    const token = getBearerToken();
    const method = getMethod();
    config.headers.Authorization = `Bearer ${method} ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    console.log(error.response);

    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.replace("/");
      alert(error.response.data);
    }

    return error;
  }
);
