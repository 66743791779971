import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import CloseIcon from "../../assets/exit-icon.svg";
import PlusIcon from "../../assets/plus-icon.svg";
import ButtonIconLow from "../../buttons/ButtonIconLow";
import ButtonIconShadowLabel from "../../buttons/ButtonIconShadowLabel";
import {
  createColor,
  deleteColorById,
  getColorsByCompany,
} from "../../../services/company_color";
import "./Color.css";
import Swal from "sweetalert2";

function Color() {
  const [valueColor, setValueColor] = useState({
    name: "",
    hex_code: "#ffffff",
  });
  const [colors, SetColors] = useState([]);

  const handleChangeName = (event) => {
    const value = event.target.value;
    setValueColor({ ...valueColor, name: value });
  };
  const handleChangeColor = (event) => {
    const value = event.target.value;
    setValueColor({ ...valueColor, hex_code: value });
  };

  const addColor = (e) => {
    e.preventDefault();
    const { name, hex_code } = valueColor;
    const newColor = { name, hex_code };

    const temp = [...colors];

    createColor(newColor).then((response) => {
      temp.unshift(response.data);
      SetColors(temp);
      setValueColor({ name: "", hex_code: "#ffffff" });
    });
  };

  const deleteColor = (id) => {
    Swal.fire({
      title: "Atención",
      text: "¿Estás seguro que deseas eliminar este color?",
      icon: "info",
      confirmButtonColor: "#28a745",
      confirmButtonText: "Aceptar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteColorById(id).then((response) => {
          if (response.status === 200) {
            SetColors((prevColors) =>
              prevColors.filter((color) => color.id !== id)
            );
          }
        });
      }
    });
  };
  useEffect(() => {
    getColorsByCompany().then((response) => {
      SetColors(response.data);
    });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <h4>Color palette</h4>
        </div>
      </div>
      <form onSubmit={addColor}>
        <div className="row">
          <div className="col-md-6 col-12 mb-1">
            <Input
              style={{ height: "7vh" }}
              id="inputss"
              placeholder="Color Name"
              onChange={handleChangeName}
              name="input-name"
              value={valueColor.name}
              required
            />
          </div>

          <div className="col-md-3 col-6  mb-1">
            <ButtonIconShadowLabel text={"#Hex"} identifier={"Mycolor"} />
            <Input
              onChange={handleChangeColor}
              id="Mycolor"
              type="color"
              name="input-hexacolor"
              value={valueColor.hex_code}
              style={{
                backgroundColor: "transparent",
                border: "none",
                borderRadius: "10px",
              }}
              required
            />
          </div>

          <div className="col-md-1 col-6 ml-0 ps-0 d-flex mb-3">
            <ButtonIconLow icon={PlusIcon} />
          </div>
        </div>
      </form>

      <div className="row">
        <div className="col-md-12">
          {colors.map((color, index) => (
            <div className="row mt-3">
              <div className="col-md-5 mb-1">
                <Input
                  style={{ height: "5vh" }}
                  id="inputss"
                  placeholder=""
                  onChange={handleChangeName}
                  name="input-name"
                  value={color.name}
                  disabled
                />
              </div>

              <div className="col-md-3 mb-2 ">
                <ButtonIconShadowLabel text={color.hex_code} disabled />
              </div>
              <div className="col-md-1">
                <ButtonIconLow
                  expression={() => deleteColor(color.id)}
                  icon={CloseIcon}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Color;
