/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import TwitterIcon from "../../assets/twitter.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import LinkedIncon from "../../assets/linkedin-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.svg";
import UploadIcon from "../../assets/upload-icon.svg";
import SaveIcon from "../../assets/save-icon.svg";
import { useStore } from "../../../hooks/useStore";
import { ThreeDots } from "react-loader-spinner";
import { updateCompany } from "../../../services/company";

function LinksCompany() {
  const { authData, setAuthData } = useStore();

  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState({});
  const [facebook, SetFacebook] = useState("");
  const [instagram, SetInstagram] = useState("");
  const [twitter, SetTwitter] = useState("");
  const [youtube, SetYoutube] = useState("");

  useEffect(() => {
    setCompany(authData.data.company);
  }, []);

  const saveCompany = () => {
    setLoading(true);
    setModified(false);
    updateCompany(company).then((response) => {
      setLoading(false);

      if (response.status === 200) {
        localStorage.setItem(
          "AuthData",
          JSON.stringify({ data: { ...authData.data, company: response.data } })
        );
        setAuthData({ data: { ...authData.data, company: response.data } });
      }
    });
  };
  return (
    <div className="container-general-links">
      <div className="row text-start">
        <h4>Website</h4>
        <Input
          value={company.website}
          style={{
            color: "white",
            backgroundColor: "rgba(255, 255, 255, 0.18)",
            border: "none",
          }}
          onChange={(e) => {
            setModified(true);
            setCompany({ ...company, website: e.target.value });
          }}
        />
      </div>

      <div className="social-media my-3 text-start">
        <h4>Social Media</h4>
        <div>
          <img className="social-icons" src={LinkedIncon} alt="youtube" />
          <Input
            value={company.linkedin}
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            onChange={(e) => {
              setModified(true);
              setCompany({ ...company, linkedin: e.target.value });
            }}
          />

          {youtube !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>
        <div>
          <img className="social-icons" src={FacebookIcon} alt="facebook" />
          <Input
            value={company.facebook}
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            onChange={(e) => {
              setModified(true);
              setCompany({ ...company, facebook: e.target.value });
            }}
          />
          {facebook !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>
        <div>
          <img className="social-icons" src={InstagramIcon} alt="instagram" />
          <Input
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            value={company.instagram}
            onChange={(e) => {
              setModified(true);
              setCompany({ ...company, instagram: e.target.value });
            }}
          />
          {instagram !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>
        <div>
          <img className="social-icons" src={TwitterIcon} alt="twitter" />
          <Input
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            value={company.twitter}
            onChange={(e) => {
              setModified(true);
              setCompany({ ...company, twitter: e.target.value });
            }}
          />
          {twitter !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>

        <div
          className="row mt-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div className="col-3">
            <ThreeDots
              // height="50%"
              width="100%"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
          </div>

          <div className="col-8">
            <div className="button-save-company ">
              <button onClick={() => saveCompany()}>
                <img src={SaveIcon} alt="ToolIcon" />
                <label>Save changes</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinksCompany;
