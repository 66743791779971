import React from "react";
import "../ComponetStylesFlex.css";
import { Outlet } from "react-router-dom";
import { NavbarPanel } from "containers/NavbarPanel";

function Categories() {
  return (
    <div className="container-fluid">
      <div className="row"></div>
      <div className="row p-0">
        <NavbarPanel
          path="/panel/categories"
          sections={[
            { label: "Categories", route: "all" },
            {
              label: "Detail",
              route: "detail",
            },
          ]}
        />
      </div>
      <div className="row mt-3">
        <div className="col-lg-10 col 12">
          <div className="line" />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col 12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Categories;
