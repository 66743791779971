import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {getResponsesBySectionId} from "services/company_section";
import {Oval} from "react-loader-spinner";
import {ExclamationCircle} from "react-bootstrap-icons";
import TimeAgo from "javascript-time-ago";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import en from "javascript-time-ago/locale/en";
import "./SectionResponse.css";

TimeAgo.setDefaultLocale(en.locale);
TimeAgo.addLocale(en);

const timeAgo = new TimeAgo("en-US");

function capitalizeFirstLetter(word) {
	if (typeof word !== "string" || word.length === 0) {
		return word; // Return the word as is if it's not a string or an empty string.
	}

	return word.charAt(0).toUpperCase() + word.slice(1);
}

const renderNotRegisteredTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Not verified by mailverse
	</Tooltip>
);

const renderRegisteredTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Verified by mailverse
	</Tooltip>
);

const SectionResponse = () => {
	const {section_id} = useParams();
	const [responses, setResponses] = useState({
		fieldOrder: [
			{
				key: "receiverMail",
				label: "Receiver mail",
			},
			{
				key: "senderMail",
				label: "Sender mail",
			},
			{
				key: "timestamp",
				label: "Timestamp",
			},
		],
		values: [],
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getResponsesBySectionId(section_id)
			.then((response) => {
				const values = response.data.map((item) => {
					const itemToReturn = {
						receiverMail:
							item.receiverUserId?.email ||
							item.receiverEmail ||
							"Not registered!",
						senderMail:
							item.senderUserId?.email ||
							item.senderEmail ||
							"Not registered!",
						timestamp: timeAgo.format(new Date(item.timestamp)),
						isSenderRegistered: item.senderUserId?.email
							? true
							: false,
						isReceiverRegistered: item.receiverUserId?.email
							? true
							: false,
					};
					for (const field of item.fields) {
						itemToReturn[
							field.idField.value.local ||
								field.idField.value.label
						] = JSON.parse(field.value).toString() || "";
					}
					return itemToReturn;
				});

				// For writing table column/storing data in proper way
				const fieldOrder = [];
				for (const field of response.data?.[0]?.fields || []) {
					fieldOrder.push({
						key:
							field.idField.value.local ||
							field.idField.value.label,
						label:
							field.idField.value.local ||
							field.idField.value.label,
					});
				}
				setResponses({values, fieldOrder});
			})
			.finally(() => setLoading(false));
	}, [section_id]);

	if (loading) {
		return (
			<Oval
				height={80}
				width={80}
				color="white"
				visible={true}
				ariaLabel="oval-loading"
				secondaryColor="black"
				strokeWidth={2}
				strokeWidthSecondary={2}
			/>
		);
	}

	if (!responses.values.length)
		return (
			<h2 style={{color: "white"}}>
				No responses found for this section!
			</h2>
		);

	return (
		<div className="container">
			<h2 style={{textAlign: "left"}}>Form Responses</h2>

			<div className="table-borderless">
				<div className="row card mt-2 table-row">
					<div className="card-body">
						<div className="row d-flex justify-content-center align-items-center">
							<div className="col d-flex justify-content-center">
								<h6 className="card-title">#</h6>
							</div>
							{/* Doing this col-5 and then again grid just to give col-2.5 to each email, 2 was very less and 3 was very large */}
							<div className="col-5">
								<div className="row">
									<h5 className="col card-title d-flex justify-content-center">
										<div>Receiver Email</div>
									</h5>
									<h5 className="col card-title d-flex justify-content-center">
										<div>Sender Email</div>
									</h5>
								</div>
							</div>
							{responses.fieldOrder.map((field) => {
								return (
									<h5
										key={field.key}
										className="col card-title d-flex justify-content-center"
									>
										<div key={field.key}>
											{capitalizeFirstLetter(field.label)}
										</div>
									</h5>
								);
							})}
							<h5 className="col card-title d-flex justify-content-center">
								<div>Timestamp</div>
							</h5>
						</div>
					</div>
				</div>

				{responses.values.map((response, i) => {
					return (
						<div
							className="row card mt-2 table-row"
							key={`response-${i}`}
						>
							<div className="card-body">
								<div className="row d-flex justify-content-center align-items-center">
									<div className="col d-flex justify-content-center">
										<h6 className="card-title">{i + 1}</h6>
									</div>
									<div className="col-5">
										<div className="row">
											<div className="col d-flex justify-content-center">
												<h6 className="card-title">
													<OverlayTrigger
														placement="right"
														overlay={
															response.isReceiverRegistered
																? renderRegisteredTooltip
																: renderNotRegisteredTooltip
														}
													>
														<h6>
															{
																response.receiverMail
															}{" "}
															{response.isReceiverRegistered ? (
																<></>
															) : (
																<ExclamationCircle fill="red" />
															)}
														</h6>
													</OverlayTrigger>
												</h6>
											</div>
											<div className="col d-flex justify-content-center">
												<h6 className="card-title">
													<OverlayTrigger
														placement="right"
														overlay={
															response.isSenderRegistered
																? renderRegisteredTooltip
																: renderNotRegisteredTooltip
														}
													>
														<h6>
															{
																response.senderMail
															}{" "}
															{response.isSenderRegistered ? (
																<></>
															) : (
																<ExclamationCircle fill="red" />
															)}
														</h6>
													</OverlayTrigger>
												</h6>
											</div>
										</div>
									</div>
									{responses.fieldOrder.map((field) => {
										return (
											<div
												key={field.key}
												className="col d-flex justify-content-center"
											>
												<h6 className="card-title">
													{response[field.key]}
												</h6>
											</div>
										);
									})}
									<div className="col d-flex justify-content-center">
										<h6 className="card-title">
											{response.timestamp}
										</h6>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export {SectionResponse};
