import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";
import { getCompanyId } from "../helpers/Utils";

const baseUrl = urlBase;
const company_id = getCompanyId();

export const getSectionsByCompany = () => {
  return axios.get(
    `${baseUrl}/company-section/get-by-company?company=${company_id}`
  );
};

export const getResponsesBySectionId = (sectionId) => {
  return axios.get(`${baseUrl}/section-form-response/response/${sectionId}`);
};

export const getFieldsByResponseId = (responseId) => {
  return axios.get(`${baseUrl}/section-form-response/fields/${responseId}`);
};

export const createSection = (section) => {
  const { category, ...body } = section;
  return axios.post(
    `${baseUrl}/company-section/create?category=${category}`,
    body
  );
};

export const changeStatusSection = (id, is_active) => {
  return axios.put(`${baseUrl}/company-section/update/${id}`, { is_active });
};

export const deleteSectionById = (props) => {
  const { id } = props;
  return axios.delete(`${baseUrl}/company-section/delete/${id}`);
};

export const saveBoard = (props) => {
  const { section_id, body } = props;

  return axios.post(
    `${baseUrl}/company-section/save-board?section=${section_id}`,
    body
  );
};

export const getBoard = (props) => {
  const { section_id } = props;

  return axios.get(
    `${baseUrl}/company-section/get-board?section=${section_id}`
  );
};
