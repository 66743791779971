import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { useStore } from "../../../hooks/useStore";
import SaveIcon from "../../assets/save-icon.svg";
import { getUsersByCompany, updateUser } from "../../../services/company_users";
import { ThreeDots } from "react-loader-spinner";
import ButtonUploadAvatar from "../../buttons/UploadInput";
import Upload from "../../assets/upload-icon.svg";
import Avatar from "../../buttons/Avatar";
import { convertNullStringsToEmpty } from "utils/objectHelpers";
import styles from "./styles.module.css";

function ListUsers() {
  const [users, SetUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    list();
  }, []);

  const list = () => {
    getUsersByCompany().then((response) => {
      let cleanedUsers = response.data.map((user) => {
        let tempUser = user;
        convertNullStringsToEmpty(tempUser);
        return tempUser;
      });
      SetUsers(cleanedUsers);
      setIsLoading(false);
    });
  };

  const { authData, setAuthData } = useStore();
  const [user, setUser] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const saveUser = () => {
    setLoading(true);
    setModified(false);
    const formData = new FormData();
    for (const property in user) {
      if (property !== "picture") {
        const value =
          typeof user[property] === "object"
            ? JSON.stringify(user[property])
            : user[property];
        formData.append(property, value);
      }
    }
    if (imageFile) {
      formData.append("image", imageFile);
    }
    updateUser(formData)
      .then((response) => {
        setImageFile(null);
        setLoading(false);

        if (response.status === 200) {
          localStorage.setItem(
            "AuthData",
            JSON.stringify({
              data: { company: authData.data.company, ...response.data },
            })
          );
          setAuthData({
            data: { company: authData.data.company, ...response.data },
          });
        }
        list();
        setPreviewUrl(null);
      })
      .catch((error) => {
        setPreviewUrl(null);
        list();
        setLoading(false);
      });
  };

  useEffect(() => {
    setUser(users[0]);
  }, [users]);

  const handleImageChange = (event) => {
    setModified(true);
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    setUser({ ...user, picture: url });
    setImageFile(file);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          {user && (
            <>
              <div className="row">
                <div className="col-12 mt-3 mb-3">
                  <div className="line" />
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-2 ">
                    <br></br>
                    <div
                      className="col avatar"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Avatar photo={user.picture} previewUrl={previewUrl} />{" "}
                    </div>
                  </div>
                  <div className="col-lg-1 text-start">
                    <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
                      Image
                    </h3>

                    <div className="row ">
                      <div className="col-12 ">
                        <ButtonUploadAvatar
                          text={""}
                          icon={Upload}
                          action={handleImageChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 text-start">
                    <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
                      Name
                    </h3>
                    <Input
                      style={{
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        height: "7vh",
                        backgroundColor: "rgba(255, 255, 255, 0.18)",
                      }}
                      value={user.name || ""}
                      onChange={(e) => {
                        setModified(true);
                        setUser({ ...user, name: e.target.value });
                      }}
                      required
                    />
                  </div>
                  <div className="col-lg-3 text-start">
                    <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
                      Email
                    </h3>
                    <Input
                      style={{
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        height: "7vh",
                        backgroundColor: "rgba(255, 255, 255, 0.18)",
                      }}
                      disabled
                      value={user.email || ""}
                    />
                  </div>

                  <div className="col-lg-3 text-start">
                    <h3 id="subtile-font" className="mb-2 mt-2 pb-1">
                      Description
                    </h3>

                    <Input
                      type="textarea"
                      style={{
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        height: "7vh",
                        backgroundColor: "rgba(255, 255, 255, 0.18)",
                      }}
                      onChange={(e) => {
                        setModified(true);
                        setUser({ ...user, description: e.target.value });
                      }}
                      value={user.description || ""}
                    />
                  </div>
                </div>
              </form>
              <div
                className="row mt-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                <div className="col-3">
                  <ThreeDots
                    // height="50%"
                    width="100%"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={loading}
                  />
                </div>

                <div className="col-6">
                  <div className="button-save-company ">
                    <button hidden={!modified} onClick={() => saveUser()}>
                      <img src={SaveIcon} alt="ToolIcon" />
                      <label>Save changes</label>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-12 mt-4 mb-4">
              <div className="line" />
            </div>
          </div>
          <br></br>
          {users.map(
            (user, index) =>
              index > 0 && (
                <div className="row  mb-2" style={{ height: "45px" }}>
                  <div className="col-2">
                    <div
                      className="col"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {user.picture ? (
                        <img
                          src={user.picture}
                          referrerPolicy="no-referrer"
                          alt="avatar"
                          className={styles.picture}
                        />
                      ) : (
                        <div className={styles.generatedPicture}>
                          <p className={styles.generatedPictureInitials}>
                            {user.name[0]}
                          </p>
                        </div>
                      )}

                      {/* <Avatar photo={user.picture} /> */}
                    </div>
                  </div>
                  <div className="col-3 text-start">
                    <Input
                      style={{ height: "5vh" }}
                      id="inputss-white"
                      value={user.name || ""}
                      disabled
                    />
                  </div>
                  <div className="col-5 text-start">
                    <Input
                      style={{ height: "5vh" }}
                      id="inputss-white"
                      value={user.email || ""}
                      disabled
                    />
                  </div>
                  {/* <div className="col-1 text-start">
            <Input
              style={{ height: "5vh" }}
              id="inputss-white"
              value={user.email_verified}
              disabled
            />
          </div> */}
                </div>
              )
          )}
        </div>
      )}
    </>
  );
}

export default ListUsers;
