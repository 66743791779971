/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { BlankBoard } from "./BlankBoard";
import { Actions } from "./Actions";
import AddElement from "./AddElement";
import { BoardContext } from "providers/boardProvider";
import { handlerGetData } from "utils/handlerServices";
import { getBoard } from "services/company_section";

const Board = ({ section_id, section_type }) => {
  const { saveSectionId, saveSectionType, chargeElements } =
    useContext(BoardContext);
  saveSectionId(section_id);
  saveSectionType(section_type);

  useEffect(() => {
    if (section_id) {
      const prev_elements = handlerGetData(getBoard, "", false, {
        section_id,
      });

      prev_elements.then((response) => {
        chargeElements(response);
      });
    }
  }, []);

  return (
    <div>
      <h1>Design board</h1>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-2">
            <Actions />
          </div>
          <div className="col-auto">
            <BlankBoard />
          </div>
          <div className="col-2">
            <AddElement />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Board };
