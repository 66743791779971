/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import TwitterIcon from "../../assets/twitter.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import LinkedIncon from "../../assets/linkedin-icon.svg";
import InstagramIcon from "../../assets/instagram-icon.svg";
import UploadIcon from "../../assets/upload-icon.svg";
import SaveIcon from "../../assets/save-icon.svg";
import { useStore } from "../../../hooks/useStore";
import { ThreeDots } from "react-loader-spinner";
import { updateUser } from "../../../services/company_users";
import { convertNullStringsToEmpty } from "utils/objectHelpers";

function LinksUser() {
  const { authData, setAuthData } = useStore();

  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({});
  const [facebook, SetFacebook] = useState("");
  const [instagram, SetInstagram] = useState("");
  const [twitter, SetTwitter] = useState("");
  const [youtube, SetYoutube] = useState("");

  useEffect(() => {
    let user = authData.data;
    convertNullStringsToEmpty(user);
    setUser(user);
  }, [authData.data]);

  const saveUser = () => {
    setLoading(true);
    setModified(false);
    const formData = new FormData();
    for (const property in user) {
      if (property !== "picture") {
        const value =
          typeof user[property] === "object"
            ? JSON.stringify(user[property])
            : user[property];
        formData.append(property, value);
      }
    }
    updateUser(formData).then((response) => {
      setLoading(false);

      if (response.status === 200) {
        localStorage.setItem(
          "AuthData",
          JSON.stringify({ data: response.data })
        );
        setAuthData({ data: response.data });
      }
    });
  };
  return (
    <div className="container-general-links">
      <div className="row text-start">
        <h4>Website</h4>
        <Input
          value={user.website || ""}
          style={{
            color: "white",
            backgroundColor: "rgba(255, 255, 255, 0.18)",
            border: "none",
          }}
          placeholder="Enter your website"
          onChange={(e) => {
            setModified(true);
            setUser({ ...user, website: e.target.value });
          }}
        />
      </div>

      <div className="social-media my-3 text-start">
        <h4>Social Media</h4>
        <div>
          <img className="social-icons" src={LinkedIncon} alt="youtube" />
          <Input
            value={user.linkedin || ""}
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            placeholder="Enter your Youtube channel"
            onChange={(e) => {
              setModified(true);
              setUser({ ...user, linkedin: e.target.value });
            }}
          />

          {youtube !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>
        <div>
          <img className="social-icons" src={FacebookIcon} alt="facebook" />
          <Input
            value={user.facebook || ""}
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            placeholder="Enter your Facebook page"
            onChange={(e) => {
              setModified(true);
              setUser({ ...user, facebook: e.target.value });
            }}
          />
          {facebook !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>
        <div>
          <img className="social-icons" src={InstagramIcon} alt="instagram" />
          <Input
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            value={user.instagram || ""}
            placeholder="Enter your Instagram page"
            onChange={(e) => {
              setModified(true);
              setUser({ ...user, instagram: e.target.value });
            }}
          />
          {instagram !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>
        <div>
          <img className="social-icons" src={TwitterIcon} alt="twitter" />
          <Input
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.18)",
              border: "none",
            }}
            value={user.twitter || ""}
            placeholder="Enter your Twitter page"
            onChange={(e) => {
              setModified(true);
              setUser({ ...user, twitter: e.target.value });
            }}
          />
          {twitter !== "" && (
            <button style={{ background: "none", border: "none" }}>
              <img
                className="social-icons mx-1 p-1"
                src={UploadIcon}
                alt="ToolIcon"
              />
            </button>
          )}
        </div>

        <div
          className="row mt-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div className="col-3">
            <ThreeDots
              // height="50%"
              width="100%"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loading}
            />
          </div>

          <div className="col-8">
            <div className="button-save-company ">
              <button onClick={() => saveUser()}>
                <img src={SaveIcon} alt="ToolIcon" />
                <label>Save changes</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinksUser;
