/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useMemo, useState } from "react";
import { saveBoard } from "services/company_section";
import { handlerCUD } from "utils/handlerServices";
import { v4 as uuidv4 } from "uuid";

const BoardContext = createContext();

const BoardProvider = ({ children }) => {
  const [deleteAction, setDeleteAction] = useState(false);
  const [editAction, setEditAction] = useState(false);
  const [previewAction, setPreviewAction] = useState(false);

  // Size of the board
  const [boardWidth, setBoardWidth] = useState(500);
  const [boardHeight, setBoardHeight] = useState(700);

  const [sectionId, setSectionId] = useState(null); // Id de la sección a editar
  const [sectionType, setSectionType] = useState(null); // Tipo de sección a editar [banner, section
  const [elements, setElements] = useState([]);

  const chargeElements = (items) => {
    setElements(items);
  };

  const changeHeight = (newHeight) => {
    if (newHeight < 700) {
      return;
    }
    setBoardHeight(newHeight);
  };

  const changeWidth = (width) => {
    if (width < 500) {
      return;
    }
    setBoardWidth(width);
  };

  // Actions applied to the board
  const addElement = (field) => {
    console.log("Agregando elemento");
    const top = getMaxTop() + 25;
    const { id, ...rest } = field;
    setElements((prevElements) => [
      ...prevElements,
      { id: `${id}-${uuidv4()}`, ...rest, top: top },
    ]);
  };

  const getMaxTop = () => {
    if (elements.length === 0) {
      return 0; // Si no hay elementos, el top máximo es 0
    }

    return elements.reduce((maxTop, element) => {
      if (element.top + element.height > maxTop) {
        return element.top + element.height;
      }
      return maxTop;
    }, elements[0].top + elements[0].height); // Comienza con el primer elemento como valor máximo inicial
  };

  const moveElement = (id, left, top) => {
    const updatedElements = elements.map((element) =>
      element.id === id
        ? {
            ...element,
            top: clamp(top, 25, boardHeight - element.height),
          }
        : element
    );

    // Chequea si el elemento actual se superpone con otros elementos
    const overlappingElements = updatedElements.filter(
      (element) =>
        element.id !== id &&
        top + element.height >= element.top &&
        top <= element.top + element.height
    );

    if (overlappingElements.length > 0) {
      const direction = top < overlappingElements[0].top ? -1 : 1;
      const offsetY = direction * overlappingElements[0].height;

      const newTop = clamp(
        top + offsetY,
        25,
        boardHeight - elements.find((el) => el.id === id).height
      );

      setElements((prevElements) =>
        prevElements.map((element) =>
          element.id === id ? { ...element, top: newTop } : element
        )
      );
    } else {
      setElements(updatedElements);
    }
  };

  const updateElement = (id, options) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id
          ? {
              ...element,
              value: options,
            }
          : element
      )
    );
  };
  const updateSize = (id, height) => {
    console.log("Actualizando tamaño");
    console.log(height);
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id
          ? {
              ...element,
              height: height,
            }
          : element
      )
    );
  };

  // Función auxiliar para limitar un valor dentro de un rango
  const clamp = (value, min, max) => {
    return Math.max(min, Math.min(value, max));
  };

  const deleteElement = (id) => {
    setElements((prevElements) =>
      prevElements.filter((element) => element.id !== id)
    );
  };

  // States for actions to apply to the board

  const changeDeleteAction = () => {
    setDeleteAction(!deleteAction);
  };

  const changeEditAction = () => {
    setEditAction(!editAction);
  };

  const changePreviewAction = () => {
    setPreviewAction(!previewAction);
  };

  // Guardar cambios
  const saveChanges = () => {
    console.log("Cambios guardados");
    console.log(elements);
    console.log(sectionId);
    console.log(sectionType);
    console.log(orderElementsByTop());
    const body = {
      items: orderElementsByTop(),
    };
    handlerCUD(
      saveBoard,
      {
        section_id: sectionId,
        body,
      },
      "Sección guardada con éxito"
    );
  };

  const orderElementsByTop = () => {
    const sortedElements = [...elements].sort((a, b) => a.top - b.top);

    sortedElements.forEach((element, index) => {
      delete element.id;
      element.position = index;
      if (element.value) {
        delete element.value.id;
      }
      if (element.style) {
        delete element.style.id;
      }
    });

    return sortedElements;
  };

  const saveSectionId = (id) => {
    setSectionId(id);
  };

  const saveSectionType = (type) => {
    setSectionType(type);
  };

  const contextValue = useMemo(
    () => ({
      deleteAction,
      changeDeleteAction,
      editAction,
      changeEditAction,
      previewAction,
      changePreviewAction,
      elements,
      addElement,
      moveElement,
      updateElement,
      deleteElement,
      saveChanges,
      updateSize,
      boardWidth,
      boardHeight,
      changeWidth,
      changeHeight,
      saveSectionId,
      sectionType,
      saveSectionType,
      chargeElements,
    }),
    [
      changeDeleteAction,
      changeEditAction,
      changePreviewAction,
      deleteAction,
      editAction,
      elements,
      moveElement,
      previewAction,
      saveChanges,
      updateSize,
      saveSectionId,
      sectionType,
      saveSectionType,
      chargeElements,
    ]
  );

  return (
    <BoardContext.Provider value={contextValue}>
      {children}
    </BoardContext.Provider>
  );
};

export { BoardContext, BoardProvider };
