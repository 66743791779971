export const getBearerToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem("bearer token") != null
        ? JSON.parse(localStorage.getItem("bearer token"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getBearerToken -> error", error);
    token = null;
  }

  return token;
};
export const getMethod = () => {
  let method = null;
  try {
    method =
      localStorage.getItem("method") != null
        ? JSON.parse(localStorage.getItem("method"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getMethod -> error", error);
    method = null;
  }

  return method;
};

export const getCompanyId = () => {
  let company = null;
  try {
    const authData =
      localStorage.getItem("AuthData") != null
        ? JSON.parse(localStorage.getItem("AuthData"))
        : null;

    company = authData.data.company.id;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCompany -> error", error);
    company = null;
  }

  return company;
};
