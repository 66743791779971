import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";

const baseUrl = urlBase;

export const loginGoogle = (token) => {
  return axios.post(`${baseUrl}/auth/login/google`, {
    token: token,
  });
};
export const loginMicrosoft = (token) => {
  return axios.post(`${baseUrl}/auth/login/microsoft`, {
    token: token,
  });
};
