import React from "react";
import { Board } from "./DesignBoards/Board";
import { BoardProvider } from "providers/boardProvider";
import { useParams } from "react-router-dom";

const DesignBoard = () => {
  const { section_id, section_type } = useParams();

  return (
    <div className="container">
      <BoardProvider>
        <Board section_id={section_id} section_type={section_type} />
      </BoardProvider>
    </div>
  );
};

export { DesignBoard };
