import React from 'react'

export default function ButtonGeneral({text,expresion,identifier,className}) {
  return (
    <div className={className}>
      <button onClick={expresion}>
        <label htmlFor={identifier}>{text}</label>
      </button>
    </div>
  );
}
