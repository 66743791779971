import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";

const baseUrl = urlBase;

export const listCompanies = () => {
  return axios.get(`${baseUrl}/company/get-all`);
};

export const getCompany = () => {
  return axios.get(`${baseUrl}/company/get-company`);
};

export const updateCompany = (company) => {
  return axios.put(`${baseUrl}/company/update`, company, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
