import React from "react";
import "./buttons.css";

export default function ButtonAction({ action, icon, type }) {
  return (
    <div>
      <button
        type="button"
        className={`btn btn-perso-${type}`}
        onClick={action}
      >
        {icon}
      </button>
    </div>
  );
}
