import axios from "axios";
import { urlBase } from "../constants/defaulValues";
import "./interceptors";

const baseUrl = urlBase;

export const getUsersByCompany = () => {
  return axios.get(`${baseUrl}/company-user/get-by-company`);
};

export const updateUser = (user) => {
  return axios.put(`${baseUrl}/company-user/update`, user, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
